import React, { useEffect, useState } from "react";
import { HashRouter as Router } from "react-router-dom";
import Routes from "./routes";
import {
  Layout,
  ConfigProvider,
  theme,
  Button,
  Drawer,
  message,
  Space,
  Spin
} from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { getSettings } from "./utils/api";
import B2BNavigation from "./components/B2BNavigation";
import UserMenu from "./components/UserMenu";
import WorkspaceSwitcher from "./components/WorkspaceSwitcher";
import NotificationIcon from "./components/NotificationIcon";
import { WorkspaceProvider, useWorkspace } from "./components/WorkspaceContext";
import CreateWorkspaceModal from "./components/CreateWorkspaceModal";

const { Header, Content } = Layout;

const AppContent = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );
  const [userRole, setUserRole] = useState('user');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [createWorkspaceVisible, setCreateWorkspaceVisible] = useState(false);
  const { activeWorkspace, loading: workspaceLoading, initialized } = useWorkspace();

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserData();
      fetchSettings();
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isAuthenticated]);

  useEffect(() => {
    if (activeWorkspace) {
      updateUserRole();
    }
  }, [activeWorkspace]);

  const updateUserRole = () => {
    try {
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');
      const workspace = userData.workspaces?.find(w => w.id === activeWorkspace?.id);
      if (workspace) {
        setUserRole(workspace.role);
      }
    } catch (error) {
      console.error('Error updating user role:', error);
    }
  };

  const fetchUserData = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');
      const workspace = userData.workspaces?.find(w => w.isActive);
      if (workspace) {
        setUserRole(workspace.role);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      message.error('Failed to load user data');
    }
  };

  const fetchSettings = async () => {
    try {
      const settings = await getSettings();
      setDarkMode(settings.darkMode);
    } catch (error) {
      console.error("Failed to fetch settings:", error);
    }
  };

  const { defaultAlgorithm, darkAlgorithm } = theme;

  if (!isAuthenticated) {
    return (
      <ConfigProvider
        theme={{
          algorithm: darkMode ? darkAlgorithm : defaultAlgorithm,
          token: {
            colorBgContainer: darkMode ? '#141414' : '#ffffff',
            colorBgElevated: darkMode ? '#1f1f1f' : '#ffffff',
            colorBgLayout: darkMode ? '#000000' : '#f0f2f5',
            colorText: darkMode ? 'rgba(255, 255, 255, 0.85)' : 'rgba(0, 0, 0, 0.85)',
            colorTextSecondary: darkMode ? 'rgba(255, 255, 255, 0.45)' : 'rgba(0, 0, 0, 0.45)',
            colorBorder: darkMode ? '#303030' : '#f0f0f0',
          },
        }}
      >
        <Router>
          <Layout style={{ minHeight: '100vh' }}>
            <Routes
              darkMode={darkMode}
              setDarkMode={setDarkMode}
              setIsAuthenticated={setIsAuthenticated}
            />
          </Layout>
        </Router>
      </ConfigProvider>
    );
  }

  if (!initialized || workspaceLoading) {
    return (
      <div style={{ 
        height: '100vh', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        background: darkMode ? '#141414' : '#ffffff'
      }}>
        <Space direction="vertical" align="center">
          <Spin size="large" />
          <div style={{ marginTop: 16, color: darkMode ? '#fff' : '#000' }}>
            Loading workspace...
          </div>
        </Space>
      </div>
    );
  }

  return (
    <ConfigProvider
      theme={{
        algorithm: darkMode ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorBgContainer: darkMode ? '#141414' : '#ffffff',
          colorBgElevated: darkMode ? '#1f1f1f' : '#ffffff',
          colorBgLayout: darkMode ? '#000000' : '#f0f2f5',
          colorText: darkMode ? 'rgba(255, 255, 255, 0.85)' : 'rgba(0, 0, 0, 0.85)',
          colorTextSecondary: darkMode ? 'rgba(255, 255, 255, 0.45)' : 'rgba(0, 0, 0, 0.45)',
          colorBorder: darkMode ? '#303030' : '#f0f0f0',
        },
      }}
    >
      <Router>
        <Layout style={{ minHeight: "100vh" }}>
          {!isMobile && <B2BNavigation userRole={userRole} />}
          
          <Layout>
            <Header
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 16px",
                background: darkMode ? '#141414' : '#fff',
                borderBottom: `1px solid ${darkMode ? '#303030' : '#f0f0f0'}`,
                height: 'auto',
                flexWrap: 'wrap',
                gap: '8px'
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                {isMobile && (
                  <Button
                    type="text"
                    icon={<MenuOutlined style={{ fontSize: "20px", color: darkMode ? '#fff' : '#000' }} />}
                    onClick={() => setDrawerVisible(true)}
                  />
                )}
                
                <Space size="middle">
                  <WorkspaceSwitcher />

                  {!activeWorkspace && (
                    <Button type="primary" onClick={() => setCreateWorkspaceVisible(true)}>
                      Create Workspace
                    </Button>
                  )}
                </Space>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                {isAuthenticated && <NotificationIcon />}
                <UserMenu 
                  darkMode={darkMode} 
                  setDarkMode={setDarkMode}
                  setIsAuthenticated={setIsAuthenticated}
                />
              </div>
            </Header>
        
            <Content style={{ 
              background: darkMode ? '#000000' : '#f0f2f5',
              minHeight: 'calc(100vh - 64px)',
              padding: '24px'
            }}>
              {activeWorkspace ? (
                <Routes
                  darkMode={darkMode}
                  setDarkMode={setDarkMode}
                  setIsAuthenticated={setIsAuthenticated}
                  userRole={userRole}
                />
              ) : (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  gap: '16px',
                  padding: '24px',
                  textAlign: 'center'
                }}>
                  <h2 style={{ color: darkMode ? '#fff' : '#000' }}>
                    Welcome to CommentFusion
                  </h2>
                  <p style={{ color: darkMode ? 'rgba(255, 255, 255, 0.45)' : 'rgba(0, 0, 0, 0.45)' }}>
                    Create a workspace to get started with your team
                  </p>
                  <Button 
                    type="primary" 
                    size="large"
                    onClick={() => setCreateWorkspaceVisible(true)}
                  >
                    Create Your First Workspace
                  </Button>
                </div>
              )}
            </Content>
          </Layout>

          {isMobile && (
            <Drawer
              placement="left"
              onClose={() => setDrawerVisible(false)}
              open={drawerVisible}
              width={256}
              styles={{
                header: {
                  display: 'none'
                },
                body: {
                  padding: 0,
                  background: darkMode ? '#141414' : '#fff'
                }
              }}
            >
              <B2BNavigation userRole={userRole} />
            </Drawer>
          )}

          <CreateWorkspaceModal
            visible={createWorkspaceVisible}
            onClose={() => setCreateWorkspaceVisible(false)}
          />
        </Layout>
      </Router>
    </ConfigProvider>
  );
};

function App() {
  return (
    <WorkspaceProvider>
      <AppContent />
    </WorkspaceProvider>
  );
}

export default App;