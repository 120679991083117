import axios from "axios";

export const API_URL = "https://api.commentfusion.com/api/";

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `${token}`;
  } else {
    delete config.headers.Authorization;
  }
  return config;
});

// Auth endpoints
export const login = async (email, password) => {
  const response = await axiosInstance.post("/auth/login", { email, password });
  const { token, user } = response.data;
  
  // Store token
  localStorage.setItem("token", token);

  // Transform and store user data
  const userData = {
    id: user.id,
    username: user.username,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    workspaces: user.workspaces.map(workspace => ({
      id: workspace.id,
      name: workspace.name,
      role: workspace.role,
      isActive: workspace.isActive
    })),
    activeWorkspace: user.activeWorkspace ? {
      id: user.activeWorkspace.id,
      name: user.activeWorkspace.name,
      role: user.activeWorkspace.role
    } : null
  };

  // Store user data
  localStorage.setItem("userData", JSON.stringify(userData));
  
  return {
    token,
    user: userData
  };
};

export const register = async (values) => {
  const { email, password, firstName, lastName, jobTitle } = values;
  const response = await axiosInstance.post("/auth/register", {
    email,
    password,
    firstName,
    lastName,
    jobTitle
  });
  return response.data;
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userData");
};

// Workspace endpoints
export const getWorkspaces = async () => {
  const response = await axiosInstance.get('/workspaces');
  return response.data;
};

export const getWorkspace = async (workspaceId) => {
  const response = await axiosInstance.get(`/workspaces/${workspaceId}`);
  return response.data;
};

export const createWorkspace = async (data) => {
  const response = await axiosInstance.post('/workspaces', data);
  return response.data;
};

export const updateWorkspace = async (workspaceId, data) => {
  const response = await axiosInstance.put(`/workspaces/${workspaceId}`, data);
  return response.data;
};

export const deleteWorkspace = async (workspaceId) => {
  const response = await axiosInstance.delete(`/workspaces/${workspaceId}`);
  return response.data;
};

export const switchWorkspace = async (workspaceId) => {
  const response = await axiosInstance.post(`/workspaces/switch/${workspaceId}`);
  return response.data; // This now includes both token and user data
};

export const inviteToWorkspace = async (workspaceId, email, role = 'member') => {
  const response = await axiosInstance.post(`/workspaces/${workspaceId}/members/invite`, {
    email,
    role
  });
  return response.data;
};

export const getWorkspaceMembers = async (workspaceId) => {
  const response = await axiosInstance.get(`/workspaces/${workspaceId}/members`);
  return response.data;
};

export const updateWorkspaceMemberRole = async (workspaceId, memberId, role) => {
  const response = await axiosInstance.put(`/workspaces/${workspaceId}/members/${memberId}/role`, {
    role
  });
  return response.data;
};

export const removeWorkspaceMember = async (workspaceId, memberId) => {
  const response = await axiosInstance.delete(`/workspaces/${workspaceId}/members/${memberId}`);
  return response.data;
};

// User endpoints
export const getUserProfile = async () => {
  const response = await axiosInstance.get('/user/profile');
  return response.data;
};

export const updateUserProfile = async (formData) => {
  const response = await axiosInstance.put('/user/profile', formData);
  return response.data;
};

export const getSettings = async () => {
  const response = await axiosInstance.get("/user/settings");
  return response.data;
};

export const updateSettings = async (settings) => {
  const response = await axiosInstance.put("/user/settings", settings);
  return response.data;
};

export const getUserHistory = async () => {
  const response = await axiosInstance.get("/user/history");
  return response.data;
};

export const getAllUsers = async () => {
  const response = await axiosInstance.get('/user/all');
  return response.data;
};

export const updateUserStatus = async (userId, status) => {
  const response = await axiosInstance.put(`/user/${userId}/status`, { status });
  return response.data;
};

// Comment endpoints
export const getComments = async (url, channelId) => {
  const response = await axiosInstance.get("/comments", { 
    params: { url, channelId } 
  });
  return response.data;
};

export const postComment = async ({ url, content, parentId, channelId }) => {
  const response = await axiosInstance.post("/comments", { 
    url, 
    content, 
    parentId,
    channelId 
  });
  return response.data;
};

export const updateComment = async (commentId, content) => {
  const response = await axiosInstance.put(`/comments/${commentId}`, { content });
  return response.data;
};

export const deleteComment = async (commentId) => {
  console.log(commentId);
  const response = await axiosInstance.delete(`/comments/${commentId}`);
  return response.data;
};

export const voteComment = async ({ commentId, voteType }) => {
  const response = await axiosInstance.post("/comments/vote", { 
    commentId, 
    voteType 
  });
  return response.data;
};

export const getReplies = async (commentId) => {
  const response = await axiosInstance.get(`/comments/${commentId}/replies`);
  return response.data;
};

// Note endpoints
export const getNotes = async (url, channelId) => {
  const response = await axiosInstance.get("/notes", {
    params: { url, channelId }
  });
  return response.data;
};

export const addNote = async ({ url, content, channelId }) => {
  const response = await axiosInstance.post("/notes", {
    url,
    content,
    channelId
  });
  return response.data;
};

export const updateNote = async (noteId, content) => {
  const response = await axiosInstance.put("/notes", {
    id: noteId,
    content
  });
  return response.data;
};

export const deleteNote = async (noteId) => {
  const response = await axiosInstance.delete(`/notes/${noteId}`);
  return response.data;
};

// Channel endpoints
export const getChannels = async (url) => {
  const response = await axiosInstance.get('/channels', {
    params: { url }
  });
  return response.data;
};

export const createChannel = async (data) => {
  const response = await axiosInstance.post('/channels', data);
  return response.data;
};

export const updateChannel = async (channelId, data) => {
  const response = await axiosInstance.put(`/channels/${channelId}`, data);
  return response.data;
};

export const deleteChannel = async (channelId) => {
  const response = await axiosInstance.delete(`/channels/${channelId}`);
  return response.data;
};

export const getChannelMembers = async (channelId) => {
  const response = await axiosInstance.get(`/channels/${channelId}/members`);
  return response.data;
};

export const addChannelMember = async (channelId, data) => {
  const response = await axiosInstance.post(`/channels/${channelId}/members`, data);
  return response.data;
};

export const removeChannelMember = async (channelId, userId) => {
  const response = await axiosInstance.delete(`/channels/${channelId}/members/${userId}`);
  return response.data;
};

export const leaveChannel = async (channelId) => {
  const response = await axiosInstance.post(`/channels/${channelId}/leave`);
  return response.data;
};

export const getPublicChannels = async (url) => {
  const response = await axiosInstance.get('/channels/public', {
    params: { url }
  });
  return response.data;
};

// Other utility endpoints
export const getTrendingComments = async () => {
  const response = await axiosInstance.get("/trending/comments");
  return response.data;
};

export const getUrlMetadata = async (url) => {
  try {
    const response = await axiosInstance.get('/url/metadata', {
      params: { url }
    });
    return response.data;
  } catch (error) {
    if (error.response?.data?.fallback) {
      return error.response.data.fallback;
    }
    throw error;
  }
};

export const verifyEmail = async (token) => {
  const response = await axiosInstance.get(`/auth/verify/${token}`);
  return response.data;
};

export const requestPasswordReset = async (email) => {
  const response = await axiosInstance.post("/auth/request-reset", { email });
  return response.data;
};

export const resetPassword = async (token, newPassword) => {
  const response = await axiosInstance.post("/auth/reset-password", { token, newPassword });
  return response.data;
};

export const validateInvitation = async (token) => {
  const response = await axiosInstance.get(`/auth/invitation/${token}`);
  return response.data;
};

export const registerWithInvitation = async (data) => {
  const response = await axiosInstance.post("/auth/register-invited", data);
  return response.data;
};

// Domain restriction endpoints
export const getDomainRestrictions = async () => {
  const response = await axiosInstance.get('/domain-restrictions');
  return response.data;
};

export const addDomainRestriction = async (data) => {
  const response = await axiosInstance.post('/domain-restrictions', data);
  return response.data;
};

export const updateDomainRestriction = async (restrictionId, data) => {
  const response = await axiosInstance.put(`/domain-restrictions/${restrictionId}`, data);
  return response.data;
};

export const deleteDomainRestriction = async (restrictionId) => {
  const response = await axiosInstance.delete(`/domain-restrictions/${restrictionId}`);
  return response.data;
};

// Notification endpoints
export const getNotifications = async (params = {}) => {
  const response = await axiosInstance.get('/notifications', { params });
  return response.data;
};

export const markAsRead = async (notificationIds) => {
  const response = await axiosInstance.post('/notifications/mark-read', {
    notificationIds
  });
  return response.data;
};

export const getUnreadCount = async () => {
  const response = await axiosInstance.get('/notifications/unread-count');
  return response.data;
};

export const getNotificationStats = async () => {
  const response = await axiosInstance.get('/notifications/stats');
  return response.data;
};

// Slack Integration endpoints
export const connectSlackWorkspace = async (code) => {
  const response = await axiosInstance.post('/slack/connect', { code });
  return response.data;
};

export const getSlackConnection = async () => {
  const response = await axiosInstance.get('/slack/status');
  return response.data;
};

export const disconnectSlack = async () => {
  const response = await axiosInstance.post('/slack/disconnect');
  return response.data;
};

export const getSlackChannels = async () => {
  const response = await axiosInstance.get('/slack/channels');
  return response.data;
};

export const createChannelMapping = async (data) => {
  const response = await axiosInstance.post('/slack/mappings', data);
  return response.data;
};

export const getChannelMappings = async () => {
  const response = await axiosInstance.get('/slack/mappings');
  return response.data;
};

export const updateChannelMapping = async (mappingId, data) => {
  const response = await axiosInstance.put(`/slack/mappings/${mappingId}`, data);
  return response.data;
};

export const deleteChannelMapping = async (mappingId) => {
  const response = await axiosInstance.delete(`/slack/mappings/${mappingId}`);
  return response.data;
};

export const postToSlack = async ({ channelId, content, metadata }) => {
  const response = await axiosInstance.post('/slack/post', {
    channelId,
    content,
    metadata
  });
  return response.data;
};