import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Dropdown, Avatar, Space, theme } from 'antd';
import { 
  UserOutlined, 
  SettingOutlined, 
  LogoutOutlined,
  BulbOutlined
} from '@ant-design/icons';

const UserMenu = ({ darkMode, setDarkMode, setIsAuthenticated }) => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const { token } = theme.useToken();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    setIsAuthenticated(false);
    navigate('/auth');
  };

  const menuItems = [
    {
      key: 'profile',
      label: 'Profile',
      icon: <UserOutlined />,
      onClick: () => navigate('/profile')
    },
    {
      key: 'settings',
      label: 'Settings',
      icon: <SettingOutlined />,
      onClick: () => navigate('/settings')
    },
    {
      key: 'theme',
      label: darkMode ? 'Light Mode' : 'Dark Mode',
      icon: <BulbOutlined />,
      onClick: () => setDarkMode(!darkMode)
    },
    {
      type: 'divider'
    },
    {
      key: 'logout',
      label: 'Logout',
      icon: <LogoutOutlined />,
      onClick: handleLogout
    }
  ];

  return (
    <Dropdown
      menu={{ items: menuItems }}
      trigger={['click']}
      placement="bottomRight"
    >
      <Space 
        className="cursor-pointer" 
        style={{ 
          padding: '4px 8px',
          borderRadius: 4,
          transition: 'all 0.3s'
        }}
      >
        <Avatar 
          size="small"
          src={userData.profilePictureUrl} 
          icon={<UserOutlined />}
        />
        <span style={{ 
          color: darkMode ? token.colorTextLight : token.colorText
        }}>
          {userData.username || 'User'}
        </span>
      </Space>
    </Dropdown>
  );
};

export default UserMenu;