import React from 'react';
import { Typography, Button, Space, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const { Title } = Typography;

const URLContentHeader = ({ url }) => {
  const isYouTubeUrl = (url) => {
    try {
      const urlObj = new URL(url);
      const hostname = urlObj.hostname.toLowerCase();
      return (
        hostname === 'youtube.com' ||
        hostname === 'www.youtube.com' ||
        hostname === 'youtu.be'
      );
    } catch (e) {
      return false;
    }
  };

  const getYouTubeVideoId = (url) => {
    try {
      const urlObj = new URL(url);
      if (urlObj.hostname === 'youtu.be') {
        return urlObj.pathname.substring(1);
      }
      const searchParams = new URLSearchParams(urlObj.search);
      return searchParams.get('v');
    } catch (e) {
      return null;
    }
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(url)
      .then(() => message.success('URL copied to clipboard'))
      .catch(() => message.error('Failed to copy URL'));
  };

  const videoId = isYouTubeUrl(url) ? getYouTubeVideoId(url) : null;

  return (
    <div className="w-full max-w-6xl mx-auto mb-6 px-4">
      <Space direction="vertical" className="w-full" size="large">
        <div className="flex items-center justify-between">
          <Title level={4} className="m-0 break-all pr-4">
            {decodeURIComponent(url)}
          </Title>
          <Button
            icon={<CopyOutlined className="w-4 h-4" />}
            onClick={handleCopyUrl}
            className="flex-shrink-0"
          >
            Copy URL
          </Button>
        </div>
        
        {videoId && (
          <div style={{ 
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <div style={{ 
              width: '100%',
              maxWidth: '1000px',
              height: '400px',
              '@media (max-width: 768px)': {
                height: '240px'
              }
            }}>
              <iframe
                style={{
                  width: '100%',
                  height: '100%',
                  border: 0
                }}
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        )}
      </Space>
    </div>
  );
};

export default URLContentHeader;