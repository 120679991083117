import React, { useState } from 'react';
import { 
  Table, 
  Tag, 
  Button, 
  Space, 
  Modal, 
  message, 
  Tooltip,
  Typography 
} from 'antd';
import { 
  UserOutlined, 
  LockOutlined, 
  UnlockOutlined,
  ExclamationCircleOutlined 
} from '@ant-design/icons';

const { Title } = Typography;

const UserStatusManagement = ({ users, onStatusUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [actingUserId, setActingUserId] = useState(null);

  const handleStatusChange = (user, newStatus) => {
    Modal.confirm({
      title: `${newStatus === 'active' ? 'Enable' : 'Suspend'} User Account`,
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to ${newStatus === 'active' ? 'enable' : 'suspend'} ${user.username}'s account? ${
        newStatus === 'suspended' ? 
        'This will remove their access to the workspace and all its resources.' : 
        'This will restore their access to the workspace.'
      }`,
      okText: newStatus === 'suspended' ? 'Suspend' : 'Enable',
      okType: newStatus === 'suspended' ? 'danger' : 'primary',
      cancelText: 'Cancel',
      onOk: async () => {
        setLoading(true);
        setActingUserId(user.id);
        try {
          await onStatusUpdate(user.id, newStatus);
          message.success(`User ${newStatus === 'active' ? 'enabled' : 'suspended'} successfully`);
        } catch (error) {
          if (error.response?.data?.error) {
            message.error(error.response.data.error);
          } else {
            message.error(`Failed to ${newStatus === 'active' ? 'enable' : 'suspend'} user`);
          }
        } finally {
          setLoading(false);
          setActingUserId(null);
        }
      }
    });
  };

  const columns = [
    {
      title: 'User',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => (
        <Space>
          <UserOutlined />
          {text}
        </Space>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => (
        <Tag color={record.status === 'active' ? 'green' : 'red'}>
          {record.status === 'active' ? 'Active' : 'Suspended'}
        </Tag>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          {record.status === 'active' ? (
            <Tooltip title="Suspend User">
              <Button 
                danger
                icon={<LockOutlined />}
                onClick={() => handleStatusChange(record, 'suspended')}
                loading={loading && actingUserId === record.id}
              >
                Suspend
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Enable User">
              <Button
                type="primary"
                icon={<UnlockOutlined />}
                onClick={() => handleStatusChange(record, 'active')}
                loading={loading && actingUserId === record.id}
              >
                Enable
              </Button>
            </Tooltip>
          )}
        </Space>
      )
    }
  ];

  return (
    <div>
      <Title level={4} style={{ marginBottom: 24 }}>User Status Management</Title>
      <Table
        columns={columns}
        dataSource={users}
        rowKey="id"
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} users`
        }}
      />
    </div>
  );
};

export default UserStatusManagement;