import React, { useState, useEffect } from 'react';
import { 
  Card,
  Button,
  Table,
  Tag,
  Space,
  message,
  Typography,
  Modal,
  Form,
  Select,
  Switch,
  Input,
  Tooltip
} from 'antd';
import {
  SlackOutlined,
  SettingOutlined,
  DeleteOutlined,
  PlusOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { useWorkspace } from '../components/WorkspaceContext';
import {
  getChannelMappings,
  getSlackChannels,
  getChannels,
  createChannelMapping,
  updateChannelMapping,
  deleteChannelMapping
} from '../utils/api';

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const ChannelMappings = () => {
  const { activeWorkspace } = useWorkspace();
  const [mappings, setMappings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingMapping, setEditingMapping] = useState(null);
  const [availableChannels, setAvailableChannels] = useState([]);
  const [slackChannels, setSlackChannels] = useState([]);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (activeWorkspace) {
      fetchData();
    }
  }, [activeWorkspace]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [mappingsResponse, channelsResponse, slackResponse] = await Promise.all([
        getChannelMappings(),
        getChannels(),
        getSlackChannels()
      ]);
      
      setMappings(mappingsResponse);
      setAvailableChannels(channelsResponse);
      setSlackChannels(slackResponse);
    } catch (error) {
      message.error('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);
      if (editingMapping) {
        await updateChannelMapping(editingMapping.id, values);
        message.success('Channel mapping updated successfully');
      } else {
        // Find the selected Slack channel to get its name
        const slackChannel = slackChannels.find(
          channel => channel.id === values.slackChannelId
        );
        
        // Add the Slack channel name to the values
        const mappingData = {
          ...values,
          slackChannelName: slackChannel.name
        };
        
        await createChannelMapping(mappingData);
        message.success('Channel mapping created successfully');
      }
      setModalVisible(false);
      form.resetFields();
      fetchData();
    } catch (error) {
      message.error('Failed to save channel mapping');
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async (mappingId) => {
    try {
      await deleteChannelMapping(mappingId);
      message.success('Channel mapping deleted successfully');
      fetchData();
    } catch (error) {
      message.error('Failed to delete channel mapping');
    }
  };

  const columns = [
    {
      title: 'CommentFusion Channel',
      dataIndex: 'channelName',
      key: 'channelName',
      render: (text, record) => (
        <Space>
          <Text strong>{record.Channel.name}</Text>
          {record.channel?.isPublic && (
            <Tag color="green">Public</Tag>
          )}
        </Space>
      )
    },
    {
      title: 'Slack Channel',
      key: 'slackChannel',
      render: (_, record) => (
        <Space>
          <SlackOutlined />
          <Text>#{record.slackChannelName}</Text>
        </Space>
      )
    },
    {
      title: 'Sync Settings',
      key: 'syncSettings',
      render: (_, record) => (
        <Space>
          {record.syncComments && <Tag color="blue">Comments</Tag>}
          {record.syncNotes && <Tag color="cyan">Notes</Tag>}
          {record.includeMetadata && <Tag color="purple">Metadata</Tag>}
        </Space>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit Mapping">
            <Button
              icon={<SettingOutlined />}
              onClick={() => {
                setEditingMapping(record);
                form.setFieldsValue(record);
                setModalVisible(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete Mapping">
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: 'Delete Channel Mapping',
                  content: 'Are you sure you want to delete this mapping?',
                  okText: 'Delete',
                  okType: 'danger',
                  onOk: () => handleDelete(record.id)
                });
              }}
            />
          </Tooltip>
        </Space>
      )
    }
  ];

  return (
    <>
      <Card title={
        <Space>
          <SlackOutlined />
          <Title level={4} style={{ margin: 0 }}>Channel Mappings</Title>
        </Space>
      }>
        <div className="mb-4">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setEditingMapping(null);
              form.resetFields();
              setModalVisible(true);
            }}
          >
            Create Channel Mapping
          </Button>
        </div>

        <Table
          loading={loading}
          columns={columns}
          dataSource={mappings}
          rowKey="id"
        />
      </Card>

      <Modal
        title={editingMapping ? 'Edit Channel Mapping' : 'Create Channel Mapping'}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingMapping(null);
          form.resetFields();
        }}
        footer={null}
        width={600}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="channelId"
            label="CommentFusion Channel"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select channel"
              disabled={!!editingMapping}
            >
              {availableChannels.map(channel => (
                <Option key={channel.id} value={channel.id}>
                  {channel.name}
                  {channel.isPublic && ' (Public)'}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="slackChannelId"
            label="Slack Channel"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Slack channel"
              disabled={!!editingMapping}
            >
              {slackChannels.map(channel => (
                <Option key={channel.id} value={channel.id}>
                  #{channel.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Sync Settings"
            required
          >
            <Space direction="vertical" className="w-full">
              <Form.Item
                name="syncComments"
                valuePropName="checked"
                noStyle
              >
                <Switch checkedChildren="Sync Comments" unCheckedChildren="Don't Sync Comments" />
              </Form.Item>

              <Form.Item
                name="syncNotes"
                valuePropName="checked"
                noStyle
              >
                <Switch checkedChildren="Sync Notes" unCheckedChildren="Don't Sync Notes" />
              </Form.Item>

              <Form.Item
                name="includeMetadata"
                valuePropName="checked"
                noStyle
              >
                <Switch checkedChildren="Include Metadata" unCheckedChildren="No Metadata" />
              </Form.Item>
            </Space>
          </Form.Item>

          <Form.Item
            name="messageTemplate"
            label="Message Template"
            tooltip="Use variables like {author}, {content}, {url}"
          >
            <TextArea
              rows={4}
              placeholder="Optional custom message template"
            />
          </Form.Item>

          <Form.Item className="mb-0 text-right">
            <Space>
              <Button onClick={() => setModalVisible(false)}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={submitting}>
                {editingMapping ? 'Update' : 'Create'} Mapping
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChannelMappings;