import React, { useState, useEffect } from 'react';
import { Badge, Button, List, Avatar, Empty, Spin, Dropdown, Typography, Space, Tabs, Switch, Tooltip, message } from 'antd';
import { 
  BellOutlined, 
  UserOutlined, 
  CheckCircleOutlined, 
  ReloadOutlined,
  CheckOutlined,
  HeartOutlined,
  MessageOutlined
} from '@ant-design/icons';
import { getNotifications, getUnreadCount, markAsRead, getNotificationStats } from '../utils/api';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

const NotificationIcon = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showRead, setShowRead] = useState(false);
  const [activeTab, setActiveTab] = useState('all');
  const navigate = useNavigate();

  useEffect(() => {
    fetchNotifications(true);
    fetchNotificationStats();
    const interval = setInterval(fetchUnreadCount, 30000);
    return () => clearInterval(interval);
  }, [page, showRead, activeTab]); // Added activeTab as dependency

  const fetchNotifications = async (reset = false) => {
    if (reset) {
      setPage(1);
      setNotifications([]);
    }
    
    setLoading(true);
    try {
      // Pass the type parameter only if not 'all'
      const params = {
        page: reset ? 1 : page,
        pageSize: 10,
        showRead,
        ...(activeTab !== 'all' && { type: activeTab })
      };
      
      const response = await getNotifications(params);
      
      setNotifications(prev => 
        reset ? response.notifications : [...prev, ...response.notifications]
      );
      setHasMore(response.pagination.hasMore);
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchNotificationStats = async () => {
    try {
      const response = await getNotificationStats();
      setStats(response.stats);
      setUnreadCount(response.totalUnread);
    } catch (error) {
      console.error('Failed to fetch notification stats:', error);
    }
  };

  const fetchUnreadCount = async () => {
    try {
      const { count } = await getUnreadCount();
      setUnreadCount(count);
    } catch (error) {
      console.error('Failed to fetch unread count:', error);
    }
  };

  const getTabItems = () => {
    const items = [{ key: 'all', label: 'All' }];

    if (stats) {
      const typeStats = {
        reply: 0,
        mention: 0,
        like: 0
      };

      // Aggregate counts by type
      stats.forEach(stat => {
        if (typeStats[stat.type] !== undefined) {
          typeStats[stat.type] += stat.count;
        }
      });

      // Add tabs for types with notifications
      Object.entries(typeStats).forEach(([type, count]) => {
        if (count > 0) {
          items.push({
            key: type,
            label: `${type.charAt(0).toUpperCase() + type.slice(1)}s (${count})`
          });
        }
      });
    }

    return items;
  };

  const handleNotificationClick = async (notification) => {
    if (!notification.read) {
      try {
        await markAsRead([notification.id]);
        setNotifications(prev => 
          prev.map(n => n.id === notification.id ? { ...n, read: true } : n)
        );
        setUnreadCount(prev => Math.max(0, prev - 1));
      } catch (error) {
        console.error('Failed to mark notification as read:', error);
      }
    }
    navigate(`/comments?url=${encodeURIComponent(notification.url)}`);
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'reply':
        return <MessageOutlined style={{ color: '#1890ff' }} />;
      case 'like':
        return <HeartOutlined style={{ color: '#f5222d' }} />;
      case 'mention':
        return <MessageOutlined style={{ color: '#722ed1' }} />;
      default:
        return <BellOutlined />;
    }
  };

  const renderNotificationItem = (notification) => (
    <List.Item
      key={notification.id}
      onClick={() => handleNotificationClick(notification)}
      style={{
        backgroundColor: notification.read ? 'transparent' : 'rgba(24, 144, 255, 0.1)',
        cursor: 'pointer',
        padding: '12px 16px',
        transition: 'background-color 0.3s'
      }}
      className="hover:bg-gray-50"
    >
      <List.Item.Meta
        avatar={
          <Space>
            <Avatar
              src={notification.actor?.profilePicture}
              icon={<UserOutlined />}
            />
            {!notification.read && (
              <Badge status="processing" />
            )}
          </Space>
        }
        title={
          <Space align="baseline">
            <Space>
              {getNotificationIcon(notification.type)}
              <Text strong>{notification.actor?.username}</Text>
            </Space>
            <Text type="secondary" style={{ fontSize: '12px' }}>
              • {new Date(notification.createdAt).toLocaleString()}
            </Text>
          </Space>
        }
        description={
          <div style={{ marginLeft: 24 }}>
            <Text>
              {notification.type === 'reply' && 'replied to your comment:'}
              {notification.type === 'like' && 'liked your comment:'}
              {notification.type === 'mention' && 'mentioned you in a comment:'}
            </Text>
            <div style={{ 
              color: '#666', 
              marginTop: 4,
              maxHeight: '3em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              background: notification.read ? 'rgba(0, 0, 0, 0.02)' : 'rgba(24, 144, 255, 0.05)',
              padding: 8,
              borderRadius: 4,
              border: notification.read ? '1px solid rgba(0, 0, 0, 0.06)' : '1px solid rgba(24, 144, 255, 0.1)'
            }}>
              {notification.content}
            </div>
          </div>
        }
      />
    </List.Item>
  );

  const notificationPanel = (
    <div className="notification-panel" style={{ width: 400, maxHeight: '80vh', overflow: 'hidden', background: 'white', boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)' }}>
      <div style={{ 
        padding: '12px 16px', 
        borderBottom: '1px solid #f0f0f0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: 'white'
      }}>
        <Text strong>Notifications</Text>
        <Space>
          <Switch
            size="small"
            checked={showRead}
            onChange={(checked) => {
              setShowRead(checked);
              setPage(1);
              fetchNotifications(true);
            }}
            checkedChildren="All"
            unCheckedChildren="Unread"
          />
          {unreadCount > 0 && (
            <Tooltip title="Mark all as read">
              <Button
                size="small"
                icon={<CheckOutlined />}
                onClick={async () => {
                  try {
                    await markAsRead([], true);
                    setNotifications(prev => prev.map(n => ({ ...n, read: true })));
                    setUnreadCount(0);
                    message.success('Marked all as read');
                  } catch (error) {
                    message.error('Failed to mark all as read');
                  }
                }}
              />
            </Tooltip>
          )}
          <Tooltip title="Refresh">
            <Button
              size="small"
              icon={<ReloadOutlined />}
              onClick={() => fetchNotifications(true)}
            />
          </Tooltip>
        </Space>
      </div>

      <Tabs
        activeKey={activeTab}
        onChange={(key) => {
          setActiveTab(key);
          setPage(1);
          fetchNotifications(true);
        }}
        items={getTabItems()}
        style={{ padding: '0 16px', background: 'white' }}
      />

      <div style={{ height: 'calc(80vh - 120px)', overflow: 'auto', background: 'white' }}>
        <List
          dataSource={notifications.filter((notification) =>
            (activeTab === 'all' || notification.type === activeTab) &&
            (showRead || !notification.read)
          )}
          renderItem={renderNotificationItem}
          loading={loading}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={showRead ? "No notifications" : "No unread notifications"}
              />
            )
          }}
        />
        {hasMore && (
          <div style={{ textAlign: 'center', margin: '12px 0' }}>
            <Button onClick={() => setPage(p => p + 1)} loading={loading}>
              Load More
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Dropdown
      overlay={notificationPanel}
      trigger={['click']}
      placement="bottomRight"
      arrow
    >
      <Badge count={unreadCount} offset={[-6, 6]}>
        <Button 
          icon={<BellOutlined />} 
          type="text"
          style={{ height: 48, width: 48 }}
        />
      </Badge>
    </Dropdown>
  );
};

export default NotificationIcon;