import React, { useState, useEffect } from 'react';
import { Form, Switch, Select, Button, message } from 'antd';
import { getSettings, updateSettings } from '../utils/api';

const { Option } = Select;

const SettingsPage = ({ darkMode, setDarkMode }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const settings = await getSettings();
      form.setFieldsValue(settings);
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch settings');
    }
  };

  const onFinish = async (values) => {
    try {
      await updateSettings(values);
      setDarkMode(values.darkMode);
      message.success('Settings updated successfully');
    } catch (error) {
      message.error('Failed to update settings');
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '50px auto' }}>
      <h2>User Settings</h2>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item name="defaultRoute" label="Default Route">
          <Select>
            <Option value="comments">Comments</Option>
            <Option value="notes">Notes</Option>
          </Select>
        </Form.Item>
        <Form.Item name="darkMode" label="Dark Mode" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save Settings
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SettingsPage;