import React, { useState } from "react";
import { Form, Input, Button, Alert } from "antd";

export const CommentForm = ({ onSubmit, initialValue = "" }) => {
  const [content, setContent] = useState(initialValue);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    if (!content.trim()) {
      return;
    }
    
    setSubmitting(true);
    setError(null);
    
    try {
      await onSubmit(content);
      setContent("");  // Clear content only on success
    } catch (err) {
      // Error message is handled by message.error in parent component
      console.error("Error in CommentForm:", err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Form onFinish={handleSubmit}>
      {error && (
        <Form.Item>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError(null)}
          />
        </Form.Item>
      )}
      <Form.Item>
        <Input.TextArea
          rows={4}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Write your comment..."
        />
      </Form.Item>
      <Form.Item>
        <Button 
          htmlType="submit" 
          type="primary" 
          loading={submitting}
          disabled={!content.trim()}
        >
          {initialValue && !initialValue.startsWith("@")
            ? "Update Comment"
            : "Submit"}
        </Button>
      </Form.Item>
    </Form>
  );
};