import React, { useState } from "react";
import { Avatar, Typography, Space, Button, Dropdown, Menu, message } from "antd";
import {
  UserOutlined,
  LikeOutlined,
  DislikeOutlined,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { CommentForm } from "./CommentForm";

const { Text, Paragraph } = Typography;

export const Comment = ({
  comment,
  addReply,
  onVote,
  isAuthenticated,
  onEdit,
  onDelete,
}) => {
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [replies, setReplies] = useState(comment.Replies || []);
  const [showReplies, setShowReplies] = useState(false);
  const [loadingReplies, setLoadingReplies] = useState(false);
  const [replyCount, setReplyCount] = useState(comment.Replies?.length || 0);
  const [deleting, setDeleting] = useState(false);

  const handleVote = async (voteType) => {
    if (!isAuthenticated) {
      message.info("Please log in to vote.");
      return;
    }
    try {
      const response = await onVote(comment.id, voteType);
      // State will be updated by parent component
    } catch (error) {
      message.error("Error voting on comment");
    }
  };

  const handleReply = async (content) => {
    try {
      if (!isAuthenticated) {
        message.info("Please log in to reply.");
        return;
      }
      
      const newReply = await addReply(comment.id, content);
      if (newReply) {
        if (showReplies) {
          setReplies(prevReplies => [...prevReplies, newReply]);
        }
        setReplyCount(prevCount => prevCount + 1);
        setShowReplyForm(false);
      }
    } catch (error) {
      console.error("Error adding reply:", error);
      message.error(error.response?.data?.error || "Failed to add reply");
    }
  };

  const handleEdit = async (content) => {
    if (!isAuthenticated || !onEdit) return;
    
    try {
      const updatedComment = await onEdit(comment.id, content);
      if (updatedComment) {
        comment.content = updatedComment.content;
        comment.updatedAt = updatedComment.updatedAt;
        setIsEditing(false);
        message.success("Comment updated successfully");
      }
    } catch (error) {
      message.error(error.response?.data?.error || "Failed to update comment");
    }
  };

  const handleDelete = async () => {
    if (!isAuthenticated || !onDelete) return;
    
    try {
      const response = await onDelete(comment.id);
      if (response.hardDeleted) {
        // Comment will be removed by parent component
        message.success("Comment deleted successfully");
      } else {
        // Comment will be marked as deleted by parent component
        message.success("Comment deleted successfully");
      }
    } catch (error) {
      message.error(error.response?.data?.error || "Failed to delete comment");
    }
  };

  const handleShowReplies = async () => {
    if (!showReplies && replyCount > 0 && replies.length === 0) {
      setLoadingReplies(true);
      try {
        const fetchedReplies = await getReplies(comment.id);
        setReplies(fetchedReplies);
      } catch (error) {
        console.error("Error fetching replies:", error);
        message.error("Failed to load replies");
      } finally {
        setLoadingReplies(false);
      }
    }
    setShowReplies(!showReplies);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<EditOutlined />} onClick={() => setIsEditing(true)}>
        Edit
      </Menu.Item>
      <Menu.Item key="2" icon={<DeleteOutlined />} onClick={handleDelete}>
        Delete
      </Menu.Item>
    </Menu>
  );

  // Check if the current user is the comment author
  const isAuthor = comment.User?.id === JSON.parse(localStorage.getItem('userData') || '{}').id;
  const showActions = isAuthenticated && !comment.deleted && comment.User?.username && isAuthor;

  const formattedDate = new Date(comment.createdAt).toLocaleString();

  return (
    <div style={{ marginLeft: comment.parentId ? "20px" : "0px" }}>
      <Space direction="vertical" size="small" style={{ display: "flex", width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Avatar 
            size={32} 
            icon={<UserOutlined />} 
            src={comment.User?.profilePictureUrl}
            style={{ flexShrink: 0 }}
          />
          <div style={{ display: "flex", alignItems: "baseline", gap: "8px", flexWrap: "wrap" }}>
            <Text strong>{comment.User?.username || "[deleted]"}</Text>
            <Text type="secondary" style={{ fontSize: "12px" }}>
              {formattedDate}
            </Text>
            {comment.updatedAt !== comment.createdAt && (
              <Text type="secondary" style={{ fontSize: "12px" }}>
                (edited)
              </Text>
            )}
          </div>
          {showActions && (
            <Dropdown menu={menu} trigger={["click"]} style={{ marginLeft: "auto" }}>
              <EllipsisOutlined style={{ cursor: "pointer" }} />
            </Dropdown>
          )}
        </div>

        {isEditing ? (
          <CommentForm 
            initialValue={comment.content} 
            onSubmit={handleEdit}
            onCancel={() => setIsEditing(false)}
          />
        ) : (
          <Paragraph style={{ margin: "8px 0" }}>
            {comment.content}
          </Paragraph>
        )}

        <Space style={{ marginBottom: "8px" }}>
          <Button
            type="text"
            icon={<LikeOutlined />}
            onClick={() => handleVote("upvote")}
            disabled={!isAuthenticated}
          >
            {comment.upvotes || 0}
          </Button>
          <Button
            type="text"
            icon={<DislikeOutlined />}
            onClick={() => handleVote("downvote")}
            disabled={!isAuthenticated}
          >
            {comment.downvotes || 0}
          </Button>
          {isAuthenticated && !comment.deleted && (
            <Button type="text" onClick={() => setShowReplyForm(!showReplyForm)}>
              Reply
            </Button>
          )}
        </Space>

        {replyCount > 0 && (
          <Button 
            type="link" 
            onClick={handleShowReplies}
            loading={loadingReplies}
            style={{ padding: 0, height: "auto", marginTop: "-8px" }}
          >
            {showReplies ? 'Hide' : `Show ${replyCount} ${replyCount === 1 ? 'reply' : 'replies'}`}
          </Button>
        )}

        {showReplyForm && (
          <div style={{ marginTop: "8px" }}>
            <CommentForm
              onSubmit={handleReply}
              initialValue={`@${comment.User?.username || "[deleted]"} `}
            />
          </div>
        )}

        {showReplies && replies.map((reply) => (
          <Comment
            key={reply.id}
            comment={reply}
            addReply={addReply}
            onVote={onVote}
            isAuthenticated={isAuthenticated}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
      </Space>
    </div>
  );
};

export default Comment;