import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { verifyEmail } from '../utils/api';
import { message } from 'antd';

const VerifyEmailPage = () => {
  const [verifying, setVerifying] = useState(true);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verify = async () => {
      try {
        await verifyEmail(token);
        message.success('Email verified successfully. You can now log in.');
        navigate('/auth');
      } catch (error) {
        message.error('Email verification failed. Please try again or contact support.');
        setVerifying(false);
      }
    };

    verify();
  }, [token, navigate]);

  if (verifying) {
    return <div>Verifying your email...</div>;
  }

  return (
    <div>
      <h1>Email Verification Failed</h1>
      <p>There was an error verifying your email. Please try again or contact support.</p>
    </div>
  );
};

export default VerifyEmailPage;