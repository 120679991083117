import React, { useState } from 'react';
import { 
  Dropdown, 
  Menu, 
  Button, 
  Avatar, 
  Space, 
  Typography 
} from 'antd';
import { 
  PlusOutlined, 
  SwapOutlined, 
  SettingOutlined,
  CheckOutlined 
} from '@ant-design/icons';
import { useWorkspace } from './WorkspaceContext';
import CreateWorkspaceModal from './CreateWorkspaceModal';

const { Text } = Typography;

const WorkspaceSwitcher = () => {
  const { workspaces, activeWorkspace, switchWorkspace } = useWorkspace();
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const menuItems = [
    {
      key: 'header',
      label: <Text strong>Your workspaces</Text>,
      type: 'group',
    },
    ...workspaces.map(workspace => ({
      key: workspace.id,
      label: (
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Space>
            <Avatar 
              size="small"
              style={{ 
                backgroundColor: '#1890ff',
                verticalAlign: 'middle' 
              }}
            >
              {workspace.name.charAt(0).toUpperCase()}
            </Avatar>
            <span>{workspace.name}</span>
          </Space>
          {workspace.id === activeWorkspace?.id && (
            <CheckOutlined style={{ color: '#52c41a' }} />
          )}
        </Space>
      ),
      onClick: () => workspace.id !== activeWorkspace?.id && switchWorkspace(workspace.id),
    })),
    { type: 'divider' },
    {
      key: 'create',
      label: 'Create New Workspace',
      icon: <PlusOutlined />,
      onClick: () => setCreateModalVisible(true),
    },
    ...(workspaces.length > 0 ? [
      {
        key: 'workspace-settings',
        label: 'Workspace Settings',
        icon: <SettingOutlined />,
      },
    ] : []),
  ];

  return (
    <>
      <Dropdown 
        menu={{ items: menuItems }} 
        trigger={['click']}
        placement="bottomLeft"
      >
        <Button type="text" style={{ height: '48px', padding: '0 16px' }}>
          <Space>
            {activeWorkspace && (
              <Avatar 
                size="small"
                style={{ 
                  backgroundColor: '#1890ff',
                  verticalAlign: 'middle' 
                }}
              >
                {activeWorkspace.name.charAt(0).toUpperCase()}
              </Avatar>
            )}
            <Text strong>{activeWorkspace?.name || 'Select a Workspace'}</Text>
            <SwapOutlined />
          </Space>
        </Button>
      </Dropdown>

      <CreateWorkspaceModal
        visible={createModalVisible}
        onClose={() => setCreateModalVisible(false)}
      />
    </>
  );
};

export default WorkspaceSwitcher;
