import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Space,
  Tag,
  message,
  Typography,
  Tooltip,
  Avatar
} from 'antd';
import {
  UserAddOutlined,
  DeleteOutlined,
  UserOutlined,
  MailOutlined
} from '@ant-design/icons';
import { useWorkspace } from '../components/WorkspaceContext';
import {
  getWorkspaceMembers,
  inviteToWorkspace,
  updateWorkspaceMemberRole,
  removeWorkspaceMember
} from '../utils/api';

const { Title } = Typography;
const { Option } = Select;

const MemberManagement = () => {
  const { activeWorkspace } = useWorkspace();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const [inviteForm] = Form.useForm();
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');

  useEffect(() => {
    if (activeWorkspace) {
      fetchMembers();
    }
  }, [activeWorkspace]);

  const fetchMembers = async () => {
    try {
      setLoading(true);
      const data = await getWorkspaceMembers(activeWorkspace.id);
      setMembers(data);
    } catch (error) {
      message.error('Failed to fetch workspace members');
    } finally {
      setLoading(false);
    }
  };

  const handleRoleChange = async (userId, role) => {
    try {
      await updateWorkspaceMemberRole(activeWorkspace.id, userId, role);
      message.success('Member role updated successfully');
      fetchMembers();
    } catch (error) {
      message.error('Failed to update member role');
    }
  };

  const handleRemoveMember = async (member) => {
    try {
      await removeWorkspaceMember(activeWorkspace.id, member.userId);
      message.success('Member removed successfully');
      fetchMembers();
    } catch (error) {
      if (error.response?.data?.error) {
        message.error(error.response.data.error);
      } else {
        message.error('Failed to remove member');
      }
    }
  };

  const handleInviteMember = async (values) => {
    try {
      await inviteToWorkspace(activeWorkspace.id, values.email, values.role);
      message.success('Invitation sent successfully');
      setInviteModalVisible(false);
      inviteForm.resetFields();
    } catch (error) {
      if (error.response?.data?.error) {
        message.error(error.response.data.error);
      } else {
        message.error('Failed to send invitation');
      }
    }
  };

  const columns = [
    {
      title: 'Member',
      key: 'member',
      render: (_, record) => (
        <Space>
          <Avatar
            src={record.User.profilePictureUrl}
            icon={<UserOutlined />}
          />
          <Space direction="vertical" size={0}>
            <span>{record.User.username}</span>
            <span style={{ fontSize: '12px', color: '#999' }}>
              {record.User.email}
            </span>
          </Space>
        </Space>
      )
    },
    {
      title: 'Role',
      key: 'role',
      render: (_, record) => (
        <Select
          value={record.role}
          style={{ width: 120 }}
          onChange={(value) => handleRoleChange(record.userId, value)}
          disabled={record.role === 'admin' || record.userId === userData.id || record.status === 'suspended'}
        >
          <Option value="admin">Admin</Option>
          <Option value="moderator">Moderator</Option>
          <Option value="user">User</Option>
        </Select>
      )
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => {
        const statusConfig = {
          active: { color: 'green', text: 'Active' },
          invited: { color: 'orange', text: 'Pending Invitation' },
          suspended: { color: 'red', text: 'Suspended' }
        };
        const status = statusConfig[record.status] || { color: 'default', text: record.status };
        return <Tag color={status.color}>{status.text}</Tag>;
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          {record.userId !== userData.id && record.role !== 'admin' && record.status !== 'suspended' && (
            <Tooltip title="Remove Member">
              <Button
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title: 'Remove Member',
                    content: 'Are you sure you want to remove this member?',
                    okText: 'Remove',
                    okType: 'danger',
                    onOk: () => handleRemoveMember(record)
                  });
                }}
              />
            </Tooltip>
          )}
        </Space>
      )
    }
  ];

  if (!activeWorkspace) {
    return (
      <div style={{ padding: '24px', textAlign: 'center' }}>
        <Title level={4}>Please select a workspace first</Title>
      </div>
    );
  }

  return (
    <div style={{ padding: '24px' }}>
      <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title level={2}>Workspace Members</Title>
        <Button
          type="primary"
          icon={<UserAddOutlined />}
          onClick={() => setInviteModalVisible(true)}
        >
          Invite Member
        </Button>
      </div>

      <Table
        loading={loading}
        columns={columns}
        dataSource={members}
        rowKey={record => record.userId}
      />

      <Modal
        title="Invite New Member"
        open={inviteModalVisible}
        onCancel={() => {
          setInviteModalVisible(false);
          inviteForm.resetFields();
        }}
        onOk={() => inviteForm.submit()}
      >
        <Form
          form={inviteForm}
          layout="vertical"
          onFinish={handleInviteMember}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Please enter email' },
              { type: 'email', message: 'Please enter a valid email' }
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder="Enter email address" />
          </Form.Item>

          <Form.Item
            name="role"
            label="Initial Role"
            initialValue="user"
          >
            <Select>
              <Option value="moderator">Moderator</Option>
              <Option value="user">User</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default MemberManagement;