import React, { useState, useEffect } from 'react';
import { Modal, Input, List, Avatar, Button, Space, Select, Typography, message } from 'antd';
import { UserOutlined, TeamOutlined } from '@ant-design/icons';
import { getChannelMembers, addChannelMember, removeChannelMember } from '../utils/api';
import { useWorkspace } from '../components/WorkspaceContext';

const { Text } = Typography;
const { Option } = Select;

const ChannelMembersModal = ({ 
  visible, 
  onClose, 
  channelId,
  isAdmin,
  currentUserId 
}) => {
  const { activeWorkspace } = useWorkspace();
  const [members, setMembers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [addMemberVisible, setAddMemberVisible] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedRole, setSelectedRole] = useState('member');
  const [workspaceMembers, setWorkspaceMembers] = useState([]);

  useEffect(() => {
    if (visible && channelId) {
      fetchMembers();
      if (activeWorkspace?.members) {
        setWorkspaceMembers(activeWorkspace.members);
      }
    }
  }, [visible, channelId, activeWorkspace]);

  const fetchMembers = async () => {
    try {
      setLoading(true);
      const data = await getChannelMembers(channelId);
      setMembers(data);
    } catch (error) {
      console.error('Failed to fetch channel members:', error);
      message.error('Failed to fetch channel members');
    } finally {
      setLoading(false);
    }
  };

  const handleAddMember = async () => {
    if (!selectedMember) {
      message.error('Please select a member to add');
      return;
    }

    try {
      await addChannelMember(channelId, {
        userId: selectedMember,
        role: selectedRole
      });
      message.success('Member added successfully');
      fetchMembers();
      setAddMemberVisible(false);
      setSelectedMember(null);
      setSelectedRole('member');
    } catch (error) {
      if (error.response?.data?.error) {
        message.error(error.response.data.error);
      } else {
        message.error('Failed to add member');
      }
    }
  };

  const handleRemoveMember = async (memberId) => {
    try {
      await removeChannelMember(channelId, memberId);
      message.success('Member removed successfully');
      fetchMembers();
    } catch (error) {
      if (error.response?.data?.error) {
        message.error(error.response.data.error);
      } else {
        message.error('Failed to remove member');
      }
    }
  };

  const filteredMembers = members.filter(member => 
    member.username?.toLowerCase().includes(searchText.toLowerCase()) ||
    member.email?.toLowerCase().includes(searchText.toLowerCase())
  );

  const availableMembers = workspaceMembers.filter(
    workspaceMember => !members.find(member => member.id === workspaceMember.id)
  );

  return (
    <Modal
      title="Channel Members"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
          <Input
            placeholder="Search members..."
            prefix={<UserOutlined />}
            onChange={e => setSearchText(e.target.value)}
            style={{ width: 240 }}
          />
          {isAdmin && (
            <Button
              type="primary"
              icon={<TeamOutlined />}
              onClick={() => setAddMemberVisible(true)}
            >
              Add Member
            </Button>
          )}
        </div>

        <List
          loading={loading}
          dataSource={filteredMembers}
          renderItem={member => (
            <List.Item
              actions={[
                isAdmin && member.id !== currentUserId && (
                  <Button 
                    danger
                    onClick={() => handleRemoveMember(member.id)}
                  >
                    Remove
                  </Button>
                )
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar 
                    src={member.profilePicture} 
                    icon={<UserOutlined />}
                  />
                }
                title={member.username}
                description={
                  <Space>
                    <Text type="secondary">{member.email}</Text>
                    <Text type="secondary">·</Text>
                    <Text type="secondary">{member.role}</Text>
                  </Space>
                }
              />
            </List.Item>
          )}
        />
      </Space>

      <Modal
        title="Add Channel Member"
        open={addMemberVisible}
        onCancel={() => {
          setAddMemberVisible(false);
          setSelectedMember(null);
          setSelectedRole('member');
        }}
        onOk={handleAddMember}
        okText="Add Member"
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Select
            placeholder="Select member"
            style={{ width: '100%' }}
            onChange={value => setSelectedMember(value)}
            value={selectedMember}
          >
            {availableMembers.map(member => (
              <Option key={member.id} value={member.id}>
                {member.username} ({member.email})
              </Option>
            ))}
          </Select>

          <Select
            placeholder="Select role"
            style={{ width: '100%' }}
            onChange={value => setSelectedRole(value)}
            value={selectedRole}
          >
            <Option value="member">Member</Option>
            <Option value="moderator">Moderator</Option>
          </Select>
        </Space>
      </Modal>
    </Modal>
  );
};

export default ChannelMembersModal;