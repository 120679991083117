import React, { useState, useEffect } from 'react';
import { Modal, Input, List, Tag, Space, Button, Typography, Empty, Spin, message, Avatar } from 'antd';
import { 
  GlobalOutlined, 
  LockOutlined, 
  TeamOutlined, 
  SearchOutlined, 
  UserOutlined, 
  CrownOutlined 
} from '@ant-design/icons';
import { getPublicChannels, addChannelMember } from '../utils/api';

const { Text } = Typography;

const ChannelDirectory = ({ visible, onClose, url, onChannelJoined }) => {
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [joining, setJoining] = useState(null);

  useEffect(() => {
    if (visible && url) {
      fetchPublicChannels();
    }
  }, [visible, url]);

  const fetchPublicChannels = async () => {
    try {
      setLoading(true);
      const data = await getPublicChannels(url);
      setChannels(data);
    } catch (error) {
      console.error('Failed to fetch channels:', error);
      message.error('Failed to load channels');
    } finally {
      setLoading(false);
    }
  };

  const handleJoin = async (channelId) => {
    if (joining) return;
    
    try {
      setJoining(channelId);
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');
      
      await addChannelMember(channelId, {
        userId: userData.id,
        role: 'member'
      });

      setChannels(channels.map(channel => 
        channel.id === channelId 
          ? { ...channel, isJoined: true }
          : channel
      ));

      message.success('Successfully joined channel');
      
      if (onChannelJoined) {
        await onChannelJoined();
      }
      
      onClose();
      
    } catch (error) {
      console.error('Failed to join channel:', error);
      message.error('Failed to join channel');
    } finally {
      setJoining(null);
    }
  };

  const getScopeTag = (scope) => {
    switch (scope) {
      case 'workspace':
        return <Tag color="purple">Workspace</Tag>;
      case 'domain':
        return <Tag color="blue">Domain</Tag>;
      case 'url':
        return <Tag color="green">URL</Tag>;
      default:
        return null;
    }
  };

  const filteredChannels = channels.filter(channel => 
    channel.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    channel.description?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getChannelOwner = (channel) => {
    return channel.Users?.find(user => user.ChannelMembers?.role === 'owner');
  };

  return (
    <Modal
      title="Channel Directory"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={700}
    >
      <div style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search channels..."
          prefix={<SearchOutlined />}
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '32px 0' }}>
          <Spin size="large" />
        </div>
      ) : filteredChannels.length === 0 ? (
        <Empty 
          description="No channels found" 
          style={{ padding: '32px 0' }}
        />
      ) : (
        <List
          dataSource={filteredChannels}
          renderItem={channel => {
            const owner = getChannelOwner(channel);
            
            return (
              <List.Item
                actions={[
                  channel.isJoined ? (
                    <Text type="secondary">Joined</Text>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => handleJoin(channel.id)}
                      loading={joining === channel.id}
                      disabled={joining !== null}
                    >
                      Join Channel
                    </Button>
                  )
                ]}
              >
                <List.Item.Meta
                  title={
                    <Space>
                      {channel.isPublic ? <GlobalOutlined /> : <LockOutlined />}
                      <span>{channel.name}</span>
                      {getScopeTag(channel.scope)}
                      {owner && (
                        <Tag color="purple" icon={<CrownOutlined />}>
                          Owner: {owner.username}
                        </Tag>
                      )}
                    </Space>
                  }
                  description={
                    <Space direction="vertical" size="small">
                      <Text>{channel.description}</Text>
                      <Space size="large">
                        <Space size="small">
                          <TeamOutlined />
                          <Text type="secondary">
                            {channel.memberCount} members
                          </Text>
                        </Space>
                        {owner && (
                          <Space size="small">
                            <Avatar 
                              size="small" 
                              icon={<UserOutlined />}
                              src={owner.profilePicture}
                            />
                            <Text type="secondary">
                              Created by {owner.username}
                            </Text>
                          </Space>
                        )}
                      </Space>
                    </Space>
                  }
                />
              </List.Item>
            );
          }}
        />
      )}
    </Modal>
  );
};

export default ChannelDirectory;