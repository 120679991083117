import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import CommentPage from "./pages/CommentPage";
import AuthPage from "./pages/AuthPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import RequestResetPage from './pages/RequestResetPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import SettingsPage from './pages/SettingsPage';
import HistoryPage from './pages/HistoryPage';
import ProfilePage from './pages/ProfilePage';
import AdminDashboard from './pages/AdminDashboard';
import ChannelManagement from './pages/ChannelManagement';
import MemberManagement from './pages/MemberManagement';
import DomainRestrictions from './pages/DomainRestrictions';
import CommentsLanding from './pages/CommentsLanding';
import JoinWorkspacePage from './pages/JoinWorkspacePage';
import SlackIntegration from './pages/SlackIntegration';
import ChannelMappings from "./pages/ChannelMappings";

const PrivateRoute = ({ children, allowedRoles = ['user', 'moderator', 'admin'] }) => {
  const isAuthenticated = !!localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const activeWorkspace = userData.workspaces?.find(w => w.isActive);
  const userRole = activeWorkspace?.role || 'user';

  if (!isAuthenticated) {
    return <Navigate to="/auth" />;
  }

  if (!allowedRoles.includes(userRole)) {
    return <Navigate to="/comments-landing" />;
  }

  return children;
};

const AppRoutes = ({ darkMode, setDarkMode, setIsAuthenticated }) => (
  <Routes>
    {/* Public Routes */}
    <Route path="/auth" element={<AuthPage setIsAuthenticated={setIsAuthenticated} />} />
    <Route path="/register" element={<AuthPage initialTab="register" setIsAuthenticated={setIsAuthenticated} />} />
    <Route path="/register/:token" element={<AuthPage initialTab="register" setIsAuthenticated={setIsAuthenticated} />} />
    <Route path="/verify/:token" element={<VerifyEmailPage />} />
    <Route path="/request-reset" element={<RequestResetPage />} />
    <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
    <Route path="/join-workspace/:token" element={<JoinWorkspacePage setIsAuthenticated={setIsAuthenticated} />} />

    {/* Protected Routes - All authenticated users */}
    <Route
      path="/comments-landing"
      element={
        <PrivateRoute>
          <CommentsLanding />
        </PrivateRoute>
      }
    />
    <Route
      path="/channels"
      element={
        <PrivateRoute>
          <ChannelManagement />
        </PrivateRoute>
      }
    />
    <Route
      path="/comments"
      element={
        <PrivateRoute>
          <CommentPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/history"
      element={
        <PrivateRoute>
          <HistoryPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/profile"
      element={
        <PrivateRoute>
          <ProfilePage />
        </PrivateRoute>
      }
    />
    <Route
      path="/settings"
      element={
        <PrivateRoute>
          <SettingsPage darkMode={darkMode} setDarkMode={setDarkMode} />
        </PrivateRoute>
      }
    />

    {/* Admin-only routes */}
    <Route
      path="/dashboard"
      element={
        <PrivateRoute allowedRoles={['admin']}>
          <AdminDashboard />
        </PrivateRoute>
      }
    />
    <Route
      path="/members"
      element={
        <PrivateRoute allowedRoles={['admin']}>
          <MemberManagement />
        </PrivateRoute>
      }
    />
    <Route
      path="/domains"
      element={
        <PrivateRoute allowedRoles={['admin']}>
          <DomainRestrictions />
        </PrivateRoute>
      }
    />

    {/* Default route */}
    <Route path="/" element={<Navigate to="/comments-landing" replace />} />

    <Route
      path="/settings/integrations/slack"
      element={
        <PrivateRoute allowedRoles={['admin']}>
          <SlackIntegration />
        </PrivateRoute>
      }
    />
    <Route
      path="/settings/integrations/channel-mappings"
      element={
        <PrivateRoute allowedRoles={['admin']}>
          <ChannelMappings />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default AppRoutes;