import React, { useState } from 'react';
import { Menu, Layout, theme } from 'antd';
import {
  DashboardOutlined,
  TeamOutlined,
  MessageOutlined,
  GlobalOutlined,
  SettingOutlined,
  SlackOutlined,
  UserOutlined,
  HistoryOutlined
} from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';

const { Sider } = Layout;

const B2BNavigation = ({ userRole }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const { token } = theme.useToken();

  // Define menu items based on user role
  const getMenuItems = () => {
    const items = [
      {
        key: '/comments-landing',
        icon: <MessageOutlined />,
        label: 'Comments',
        roles: ['admin', 'moderator', 'user']
      },
      {
        key: '/history',
        icon: <HistoryOutlined />,
        label: 'History',
        roles: ['admin', 'moderator', 'user']
      },
      {
        key: '/dashboard',
        icon: <DashboardOutlined />,
        label: 'Dashboard',
        roles: ['admin']
      },
      {
        key: '/channels',
        icon: <MessageOutlined />,
        label: 'Channels',
        roles: ['admin', 'moderator', 'user']
      },
      {
        key: '/members',
        icon: <TeamOutlined />,
        label: 'Members',
        roles: ['admin']
      },
      {
        key: '/domains',
        icon: <GlobalOutlined />,
        label: 'Domain Restrictions',
        roles: ['admin']
      },
      {
        key: 'settings',
        icon: <SettingOutlined />,
        label: 'Settings',
        children: [
          {
            key: '/profile',
            icon: <UserOutlined />,
            label: 'Profile',
            roles: ['admin', 'moderator', 'user']
          },
          {
            key: '/settings/integrations/slack',
            icon: <SlackOutlined />,
            label: 'Slack Integration',
            roles: ['admin']
          },
          {
            key: '/settings/integrations/channel-mappings',
            icon: <SlackOutlined />,
            label: 'Slack Mappings',
            roles: ['admin', 'moderator']
          }
        ]
      }
    ];

    // Filter items based on user role
    return items.filter(item => {
      if (item.children) {
        item.children = item.children.filter(child => 
          child.roles.includes(userRole)
        );
        return item.children.length > 0;
      }
      return item.roles.includes(userRole);
    });
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      style={{
        background: token.colorBgContainer,
        borderRight: `1px solid ${token.colorBorder}`
      }}
    >
      <div
        style={{
          height: '150px',
          padding: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottom: `1px solid ${token.colorBorder}`,
          background: token.colorBgContainer
        }}
      >
        <img
          src="/logo.png"
          alt="Logo"
          style={{
            height: '100px',
            margin: '16px'
          }}
        />
      </div>
      <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
        defaultOpenKeys={['settings']}
        items={getMenuItems()}
        onClick={({ key }) => navigate(key)}
        style={{
          background: token.colorBgContainer,
          borderRight: 'none'
        }}
      />
    </Sider>
  );
};

export default B2BNavigation;