import React, { useEffect, useState } from "react";
import { Button, Switch, Tabs, Input, Modal, Form, Space, Badge, Typography, message, Select, Spin, Empty } from "antd";
import { 
  SearchOutlined, 
  PlusOutlined, 
  LockOutlined, 
  GlobalOutlined, 
  TeamOutlined, 
  ExportOutlined 
} from '@ant-design/icons';
import { useLocation } from "react-router-dom";
import { CommentList } from "../components/CommentList";
import { CommentForm } from "../components/CommentForm";
import { NoteList } from "../components/NoteList";
import { NoteForm } from "../components/NoteForm";
import URLContentHeader from '../components/URLContentHeader';
import ChannelMembersModal from '../components/ChannelMembersModal';
import ChannelDirectory from "../components/ChannelDirectory";
import { 
  deleteComment, 
  updateComment, 
  voteComment, 
  getChannels, 
  createChannel, 
  getComments, 
  postComment, 
  getNotes, 
  addNote, 
  leaveChannel 
} from "../utils/api";
import { useWorkspace } from '../components/WorkspaceContext';

const { Text } = Typography;
const { Option } = Select;

const getScopeTag = (scope) => {
  switch (scope) {
    case 'workspace':
      return <Badge count="Workspace" style={{ backgroundColor: '#722ed1' }} />;
    case 'domain':
      return <Badge count="Domain" style={{ backgroundColor: '#1890ff' }} />;
    case 'url':
      return <Badge count="URL" style={{ backgroundColor: '#52c41a' }} />;
    default:
      return null;
  }
};

const ChannelButton = ({ channel, isSelected, onClick }) => (
  <Button
    type={isSelected ? "primary" : "default"}
    onClick={onClick}
    className="flex items-center"
    style={{ marginRight: '8px', height: 'auto', padding: '4px 12px' }}
  >
    <Space>
      {channel.isPublic ? <GlobalOutlined /> : <LockOutlined />}
      <span>{channel.name}</span>
      {getScopeTag(channel.scope)}
    </Space>
  </Button>
);

const CommentPage = () => {
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [comments, setComments] = useState([]);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [channelModalVisible, setChannelModalVisible] = useState(false);
  const [membersModalVisible, setMembersModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("comments");
  const [directoryVisible, setDirectoryVisible] = useState(false);
  
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const url = urlParams.get("url");
  const isAuthenticated = !!localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  const [form] = Form.useForm();
  const { activeWorkspace } = useWorkspace();

  useEffect(() => {
    if (url && isAuthenticated && activeWorkspace) {
      fetchChannels();
      // Reset states when workspace changes
      setSelectedChannel(null);
      setComments([]);
      setNotes([]);
    }
  }, [url, isAuthenticated, activeWorkspace]);

  const fetchChannels = async () => {
    try {
      setLoading(true);
      const fetchedChannels = await getChannels(url);
      setChannels(fetchedChannels);
      
      // Only set selected channel and fetch content if channels exist
      if (fetchedChannels.length > 0) {
        const defaultChannel = fetchedChannels[0];
        setSelectedChannel(defaultChannel);
        await fetchChannelContent(defaultChannel.id);
      }
      
    } catch (error) {
      console.error('Error fetching channels:', error);
      message.error('Failed to fetch channels');
    } finally {
      setLoading(false);
    }
  };

  const handleEditComment = async (commentId, content) => {
    try {
      const updatedComment = await updateComment(commentId, content);
      setComments(prevComments => 
        prevComments.map(comment => 
          comment.id === commentId ? { ...comment, ...updatedComment } : comment
        )
      );
      return updatedComment;
    } catch (error) {
      message.error('Failed to update comment');
      throw error;
    }
  };
  
  const handleDeleteComment = async (commentId) => {
    try {
      const response = await deleteComment(commentId);
      
      setComments(prevComments => {
        if (response.hardDeleted) {
          return prevComments.filter(comment => comment.id !== commentId);
        } else {
          return prevComments.map(comment =>
            comment.id === commentId
              ? { ...comment, deleted: true, content: '[deleted]', User: null }
              : comment
          );
        }
      });
      
      return response;
    } catch (error) {
      message.error('Failed to delete comment');
      throw error;
    }
  };
  
  const handleVoteComment = async (commentId, voteType) => {
    try {
      const response = await voteComment({ commentId, voteType });
      setComments(prevComments =>
        prevComments.map(comment =>
          comment.id === commentId
            ? { ...comment, upvotes: response.upvotes, downvotes: response.downvotes }
            : comment
        )
      );
      return response;
    } catch (error) {
      message.error('Failed to vote on comment');
      throw error;
    }
  };

  const handleLeaveChannel = async (channelId) => {
    Modal.confirm({
      title: 'Leave Channel',
      content: 'Are you sure you want to leave this channel?',
      okText: 'Leave',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await leaveChannel(channelId);
          setSelectedChannel(null);
          await fetchChannels(); // Refresh channel list
          message.success('Successfully left the channel');
        } catch (error) {
          if (error.response?.data?.error) {
            message.error(error.response.data.error);
          } else {
            message.error('Failed to leave channel');
          }
        }
      },
    });
  };

  const fetchChannelContent = async (channelId) => {
    if (!activeWorkspace) return;
    
    try {
      const [commentData, noteData] = await Promise.all([
        getComments(url, channelId),
        getNotes(url, channelId)
      ]);
      setComments(commentData);
      setNotes(noteData);
    } catch (error) {
      message.error('Failed to fetch channel content');
    }
  };

  const handleChannelSelect = async (channelId) => {
    const channel = channels.find(c => c.id === channelId);
    setSelectedChannel(channel);
    await fetchChannelContent(channelId);
  };

  const handleCreateChannel = async (values) => {
    try {
      // Set the domain pattern based on the selected scope
      let domainPattern = '';
      switch (values.scope) {
        case 'url':
          domainPattern = url;
          break;
        case 'domain':
          domainPattern = new URL(url).hostname;
          break;
        case 'workspace':
          domainPattern = '';
          break;
      }

      const channelData = {
        ...values,
        domainPattern
      };

      const newChannel = await createChannel(channelData);
      setChannels([...channels, newChannel]);
      setSelectedChannel(newChannel);
      setChannelModalVisible(false);
      form.resetFields();
      message.success('Channel created successfully');
    } catch (error) {
      if (error.response?.data?.error) {
        message.error(error.response.data.error);
      } else {
        message.error('Failed to create channel');
      }
    }
  };

  const handleAddComment = async (content) => {
    if (!selectedChannel) {
      message.error('Please select a channel first');
      return;
    }

    try {
      const comment = await postComment({
        url,
        content,
        channelId: selectedChannel.id
      });
      setComments([comment, ...comments]);
    } catch (error) {
      message.error('Failed to add comment');
    }
  };

  const handleAddNote = async (content) => {
    if (!selectedChannel) {
      message.error('Please select a channel first');
      return;
    }

    try {
      const note = await addNote({
        url,
        content,
        channelId: selectedChannel.id
      });
      setNotes([note, ...notes]);
    } catch (error) {
      message.error('Failed to add note');
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="p-6">
        <URLContentHeader url={url} />
        <div className="text-center mt-8">
          <Text>Please log in to view and participate in discussions.</Text>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="p-6">
      <div style={{ marginBottom: '16px' }}>
        <div style={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: '16px'
        }}>
          <Text strong style={{ whiteSpace: 'nowrap' }}>Channels:</Text>
          <div style={{ 
            display: 'flex', 
            overflowX: 'auto', 
            whiteSpace: 'nowrap',
            flex: 1,
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            WebkitOverflowScrolling: 'touch'
          }}>
            {channels.map(channel => (
              <ChannelButton
                key={channel.id}
                channel={channel}
                isSelected={selectedChannel?.id === channel.id}
                onClick={() => handleChannelSelect(channel.id)}
              />
            ))}
          </div>
          <Button 
            type="primary" 
            icon={<PlusOutlined />}
            onClick={() => setChannelModalVisible(true)}
            style={{ flexShrink: 0 }}
          >
            New Channel
          </Button>
          <Button 
            icon={<SearchOutlined />}
            onClick={() => setDirectoryVisible(true)}
            style={{ marginLeft: '8px' }}
          >
            Browse Channels
          </Button>
        </div>
      </div>

      {selectedChannel ? (
        <>
          <div className="mb-6 flex items-center justify-between">
            <Space>
              <Text strong className="text-lg">{selectedChannel.name}</Text>
              {selectedChannel.isPublic ? (
                <Badge count="Public" style={{ backgroundColor: '#52c41a' }} />
              ) : (
                <Badge count="Private" style={{ backgroundColor: '#f5222d' }} />
              )}
              {getScopeTag(selectedChannel.scope)}
            </Space>
            <Space>
              <Button 
                icon={<TeamOutlined />} 
                onClick={() => setMembersModalVisible(true)}
              >
                Members ({selectedChannel.Users?.length || 0})
              </Button>
              <Button
                danger
                icon={<ExportOutlined />}
                onClick={() => handleLeaveChannel(selectedChannel.id)}
              >
                Leave Channel
              </Button>
            </Space>
          </div>

          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <Tabs.TabPane tab="Comments" key="comments">
              <CommentForm onSubmit={handleAddComment} />
              <CommentList
                comments={comments}
                addReply={async (parentId, content) => {
                  try {
                    const reply = await postComment({
                      url,
                      content,
                      parentId,
                      channelId: selectedChannel.id
                    });
                    setComments(prevComments => 
                      prevComments.map(comment => 
                        comment.id === parentId
                          ? { ...comment, Replies: [...(comment.Replies || []), reply] }
                          : comment
                      )
                    );
                    return reply;
                  } catch (error) {
                    message.error('Failed to add reply');
                    throw error;
                  }
                }}
                onVote={handleVoteComment}
                isAuthenticated={isAuthenticated}
                onEdit={handleEditComment}
                onDelete={handleDeleteComment}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Notes" key="notes">
              <NoteForm onSubmit={handleAddNote} />
              <NoteList notes={notes} setNotes={setNotes} />
            </Tabs.TabPane>
          </Tabs>
        </>
      ) : (
        <Empty
          description="Select a channel or create a new one to start discussing"
          className="mt-12"
        />
      )}

      <Modal
        title="Create New Channel"
        open={channelModalVisible}
        onCancel={() => {
          setChannelModalVisible(false);
          form.resetFields();
        }}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleCreateChannel}
          initialValues={{
            isPublic: true,
            scope: 'url'
          }}
        >
          <Form.Item
            name="name"
            label="Channel Name"
            rules={[
              { required: true, message: 'Please enter channel name' },
              { pattern: /^[a-z0-9-]+$/, message: 'Only lowercase letters, numbers, and hyphens are allowed' }
            ]}
            tooltip="Channel names should be lowercase with hyphens, e.g., 'project-discussion'"
          >
            <Input placeholder="e.g., project-discussion" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please enter a description' }]}
          >
            <Input.TextArea rows={4} placeholder="What's this channel about?" />
          </Form.Item>

          <Form.Item
            name="isPublic"
            label="Visibility"
            valuePropName="checked"
          >
            <Switch
              checkedChildren="Public"
              unCheckedChildren="Private"
            />
          </Form.Item>

          <Form.Item
            name="scope"
            label="Channel Scope"
            tooltip="Determine where this channel will be available"
          >
            <Select>
              <Option value="url">This URL only</Option>
              <Option value="domain">Entire domain</Option>
              <Option value="workspace">Entire workspace</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <ChannelMembersModal
        visible={membersModalVisible}
        onClose={() => setMembersModalVisible(false)}
        channelId={selectedChannel?.id}
        isAdmin={userData.role === 'admin'}
        currentUserId={userData.id}
      />
      
      <ChannelDirectory
        visible={directoryVisible}
        onClose={() => setDirectoryVisible(false)}
        url={url}
        onChannelJoined={fetchChannels}
      />
    </div>
  );
};

export default CommentPage;