import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Typography, Divider } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { register, registerWithInvitation, validateInvitation } from "../utils/api";

const { Text } = Typography;

export const RegisterForm = ({ onSuccess, setActiveTab }) => {
  const [loading, setLoading] = useState(false);
  const [invitation, setInvitation] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const params = useParams();
  const token = params?.token;

  useEffect(() => {
    if (token) {
      validateInvitationToken();
    }
  }, [token]);

  const validateInvitationToken = async () => {
    try {
      const response = await validateInvitation(token);
      setInvitation(response);
      form.setFieldsValue({ email: response.email });
    } catch (error) {
      message.error('Invalid or expired invitation link');
      navigate('/auth');
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (token) {
        const response = await registerWithInvitation({
          token,
          ...values
        });
        
        localStorage.setItem("token", response.token);
        localStorage.setItem("userData", JSON.stringify(response.user));
        
        message.success('Registration successful!');
        onSuccess();
      } else {
        await register(values);
        message.success('Registration successful! Please check your email to verify your account.');
        if (setActiveTab) {
          setTimeout(() => {
            setActiveTab("login");
          }, 1000);
        }
      }
    } catch (error) {
      if (error.response?.data?.error) {
        message.error(error.response.data.error);
      } else {
        message.error("Registration failed. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      requiredMark="optional"
      className="max-w-md"
    >
      {invitation ? (
        <div style={{ marginBottom: 24 }}>
          <Text>
            You've been invited to join {invitation.workspaceName}
          </Text>
        </div>
      ) : (
        <div style={{ marginBottom: 24 }}>
          <Text type="secondary">
            Please use your work email to register. If your workspace doesn't exist yet,
            you'll be able to create it.
          </Text>
        </div>
      )}

      <Form.Item
        label="Work Email"
        name="email"
        rules={[
          { required: true, message: "Please enter your work email" },
          { type: "email", message: "Please enter a valid email" }
        ]}
      >
        <Input 
          placeholder="you@yourcompany.com" 
          disabled={!!invitation}
        />
      </Form.Item>

      <Form.Item
        label="First Name"
        name="firstName"
        rules={[{ required: true, message: "Please enter your first name" }]}
      >
        <Input placeholder="First Name" />
      </Form.Item>

      <Form.Item
        label="Last Name"
        name="lastName"
        rules={[{ required: true, message: "Please enter your last name" }]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>

      <Form.Item
        label="Job Title"
        name="jobTitle"
        rules={[{ required: true, message: "Please enter your job title" }]}
      >
        <Input placeholder="Job Title" />
      </Form.Item>

      <Divider />

      <Form.Item
        label="Password"
        name="password"
        rules={[
          { required: true, message: "Please enter a password" },
          { min: 8, message: "Password must be at least 8 characters long" }
        ]}
        extra="Password must be at least 8 characters long"
      >
        <Input.Password placeholder="Create a password" />
      </Form.Item>

      <Form.Item
        label="Confirm Password"
        name="confirmPassword"
        dependencies={['password']}
        rules={[
          { required: true, message: "Please confirm your password" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The passwords do not match'));
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirm your password" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} block>
          Create Account
        </Button>
      </Form.Item>

      <div style={{ marginTop: 24, textAlign: 'center' }}>
        <Text type="secondary" style={{ fontSize: 12 }}>
          By registering, you agree to our{" "}
          <a href="/tos.txt" target="_blank">Terms of Service</a> and{" "}
          <a href="/privacy.txt" target="_blank">Privacy Policy</a>
        </Text>
      </div>
    </Form>
  );
};

export default RegisterForm;