import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Input, 
  Button, 
  Typography, 
  Card, 
  Space, 
  Empty, 
  List, 
  Avatar 
} from 'antd';
import { 
  LinkOutlined, 
  SearchOutlined, 
  UserOutlined,
  ClockCircleOutlined,
  MessageOutlined
} from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

const CommentsLanding = () => {
  const [url, setUrl] = useState('');
  const [recentUrls, setRecentUrls] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('recentUrls') || '[]');
    } catch {
      return [];
    }
  });
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (!url.trim()) return;

    // Try to parse the URL to ensure it's valid
    try {
      const parsedUrl = new URL(url.trim());
      const fullUrl = parsedUrl.toString();

      // Add to recent URLs
      const newRecentUrls = [
        { url: fullUrl, timestamp: new Date().toISOString() },
        ...recentUrls.filter(item => item.url !== fullUrl)
      ].slice(0, 5); // Keep only last 5 URLs

      setRecentUrls(newRecentUrls);
      localStorage.setItem('recentUrls', JSON.stringify(newRecentUrls));

      // Navigate to comments page
      navigate(`/comments?url=${encodeURIComponent(fullUrl)}`);
    } catch (error) {
      // If URL parsing fails, try adding https://
      try {
        const urlWithProtocol = new URL(`https://${url.trim()}`);
        navigate(`/comments?url=${encodeURIComponent(urlWithProtocol.toString())}`);
      } catch {
        // If still invalid, show error state in input
        console.error('Invalid URL');
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-8">
      <Card className="mb-8 text-center">
        <Space direction="vertical" size="large" className="w-full">
          <Title level={2}>
            <Space>
              <MessageOutlined />
              Go to Comments
            </Space>
          </Title>
          
          <Paragraph className="text-gray-500">
            Enter a URL to view or participate in discussions about that page
          </Paragraph>

          <div className="flex gap-2">
            <Input
              size="large"
              placeholder="https://example.com"
              prefix={<LinkOutlined className="text-gray-400" />}
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <Button 
              type="primary" 
              size="large"
              icon={<SearchOutlined />}
              onClick={handleSubmit}
            >
              Find Discussions
            </Button>
          </div>
        </Space>
      </Card>

      <Card title="Recent Pages" className="mb-8">
        {recentUrls.length > 0 ? (
          <List
            itemLayout="horizontal"
            dataSource={recentUrls}
            renderItem={item => (
              <List.Item
                actions={[
                  <Button 
                    type="link" 
                    onClick={() => navigate(`/comments?url=${encodeURIComponent(item.url)}`)}
                  >
                    View Comments
                  </Button>
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar icon={<LinkOutlined />} />
                  }
                  title={
                    <a 
                      href={item.url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:text-blue-700"
                    >
                      {new URL(item.url).hostname}
                    </a>
                  }
                  description={
                    <Space>
                      <ClockCircleOutlined />
                      <Text type="secondary">
                        {new Date(item.timestamp).toLocaleString()}
                      </Text>
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No recent pages"
          />
        )}
      </Card>

      <Card title="Pro Tips">
        <List>
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar icon={<SearchOutlined />} />}
              title="Quick Navigation"
              description="Just paste any URL and hit Enter to jump straight to the discussions"
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title="Join Channels"
              description="Find or create topic-specific channels to organize discussions"
            />
          </List.Item>
        </List>
      </Card>
    </div>
  );
};

export default CommentsLanding;