import React, { useState, useEffect } from 'react';
import { 
  List, 
  Input, 
  Typography, 
  Space, 
  Button, 
  DatePicker,
  Select,
  Tag,
  Card,
  Empty,
  Spin,
  Row,
  Col,
  Statistic,
  Modal,
  message
} from 'antd';
import {
  SearchOutlined,
  FilterOutlined,
  CalendarOutlined,
  MessageOutlined,
  FileTextOutlined,
  GlobalOutlined,
  DeleteOutlined,
  ClockCircleOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { getUserHistory, deleteComment, deleteNote } from '../utils/api';
import { useNavigate } from 'react-router-dom';

const { Text, Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const SearchFilters = ({ 
  onSearch, 
  onDateChange, 
  onTypeChange,
  onDomainChange,
  dateRange,
  selectedType,
  selectedDomain,
  domains
}) => (
  <Card style={{ marginBottom: 16 }}>
    <Row gutter={[16, 16]}>
      <Col xs={24} md={8}>
        <Input
          placeholder="Search content..."
          prefix={<SearchOutlined />}
          onChange={(e) => onSearch(e.target.value)}
          allowClear
        />
      </Col>
      <Col xs={24} md={8}>
        <RangePicker
          style={{ width: '100%' }}
          value={dateRange}
          onChange={onDateChange}
          placeholder={['Start Date', 'End Date']}
        />
      </Col>
      <Col xs={12} md={4}>
        <Select
          style={{ width: '100%' }}
          placeholder="Content Type"
          value={selectedType}
          onChange={onTypeChange}
          allowClear
        >
          <Option value="comment">Comments</Option>
          <Option value="note">Notes</Option>
        </Select>
      </Col>
      <Col xs={12} md={4}>
        <Select
          style={{ width: '100%' }}
          placeholder="Domain"
          value={selectedDomain}
          onChange={onDomainChange}
          allowClear
          showSearch
        >
          {domains.map(domain => (
            <Option key={domain} value={domain}>{domain}</Option>
          ))}
        </Select>
      </Col>
    </Row>
  </Card>
);

const HistoryStats = ({ stats }) => (
  <Row gutter={16} style={{ marginBottom: 16 }}>
    <Col xs={12} md={6}>
      <Card>
        <Statistic
          title="Total Items"
          value={stats.total}
          prefix={<FilterOutlined />}
        />
      </Card>
    </Col>
    <Col xs={12} md={6}>
      <Card>
        <Statistic
          title="Comments"
          value={stats.comments}
          prefix={<MessageOutlined />}
        />
      </Card>
    </Col>
    <Col xs={12} md={6}>
      <Card>
        <Statistic
          title="Notes"
          value={stats.notes}
          prefix={<FileTextOutlined />}
        />
      </Card>
    </Col>
    <Col xs={12} md={6}>
      <Card>
        <Statistic
          title="Domains"
          value={stats.domains}
          prefix={<GlobalOutlined />}
        />
      </Card>
    </Col>
  </Row>
);

const CommentItem = ({ item, onDelete }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/comments?url=${encodeURIComponent(item.url)}`);
  };

  return (
    <List.Item
      actions={[
        <Button
          type="link"
          danger
          icon={<DeleteOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(item);
          }}
        >
          Delete
        </Button>
      ]}
      onClick={handleClick}
      style={{ 
        transition: 'background-color 0.3s ease',
        cursor: 'pointer',
        padding: '16px'
      }}
      className="hover:bg-gray-50"
    >
      <List.Item.Meta
        title={
          <Space>
            <a 
              onClick={(e) => e.stopPropagation()}
              href={item.url} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {item.url}
            </a>
            <Tag color="blue" icon={<MessageOutlined />}>
              Comment
            </Tag>
          </Space>
        }
        description={
          <Space direction="vertical">
            <Text>{item.content}</Text>
            <Space>
              <ClockCircleOutlined />
              <Text type="secondary">
                {new Date(item.createdAt).toLocaleString()}
              </Text>
            </Space>
          </Space>
        }
      />
    </List.Item>
  );
};

const NoteItem = ({ item, onDelete }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/comments?url=${encodeURIComponent(item.url)}`);
  };

  return (
    <List.Item
      actions={[
        <Button
          type="link"
          danger
          icon={<DeleteOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(item);
          }}
        >
          Delete
        </Button>
      ]}
      onClick={handleClick}
      style={{ 
        transition: 'background-color 0.3s ease',
        cursor: 'pointer',
        padding: '16px'
      }}
      className="hover:bg-gray-50"
    >
      <List.Item.Meta
        title={
          <Space>
            <a 
              onClick={(e) => e.stopPropagation()}
              href={item.url} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {item.url}
            </a>
            <Tag color="green" icon={<FileTextOutlined />}>
              Note
            </Tag>
          </Space>
        }
        description={
          <Space direction="vertical">
            <div dangerouslySetInnerHTML={{ __html: item.content }} />
            <Space>
              <ClockCircleOutlined />
              <Text type="secondary">
                {new Date(item.createdAt).toLocaleString()}
              </Text>
            </Space>
          </Space>
        }
      />
    </List.Item>
  );
};

const HistoryPage = () => {
  const [comments, setComments] = useState([]);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [domains, setDomains] = useState([]);
  const [stats, setStats] = useState({
    total: 0,
    comments: 0,
    notes: 0,
    domains: 0
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = async () => {
    try {
      setLoading(true);
      const history = await getUserHistory();
      setComments(history.comments);
      setNotes(history.notes);
      
      const uniqueDomains = new Set([
        ...history.comments.map(c => new URL(c.url).hostname),
        ...history.notes.map(n => new URL(n.url).hostname)
      ]);
      
      setDomains([...uniqueDomains]);
      updateStats(history.comments, history.notes);
    } catch (error) {
      message.error('Failed to fetch history');
    } finally {
      setLoading(false);
    }
  };

  const updateStats = (comments, notes) => {
    const uniqueDomains = new Set([
      ...comments.map(c => new URL(c.url).hostname),
      ...notes.map(n => new URL(n.url).hostname)
    ]);

    setStats({
      total: comments.length + notes.length,
      comments: comments.length,
      notes: notes.length,
      domains: uniqueDomains.size
    });
  };

  const filterItems = (items) => {
    return items.filter(item => {
      const matchesSearch = !searchTerm || 
        item.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
        new URL(item.url).hostname.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesDate = !dateRange || (
        new Date(item.createdAt) >= dateRange[0].startOf('day') &&
        new Date(item.createdAt) <= dateRange[1].endOf('day')
      );

      const matchesDomain = !selectedDomain ||
        new URL(item.url).hostname === selectedDomain;

      const itemType = item.content.startsWith('<p>') ? 'note' : 'comment';
      const matchesType = !selectedType || selectedType === itemType;

      return matchesSearch && matchesDate && matchesDomain && matchesType;
    });
  };

  const handleDelete = async (item) => {
    const isComment = !item.content.startsWith('<p>');
    
    Modal.confirm({
      title: `Delete ${isComment ? 'Comment' : 'Note'}`,
      content: `Are you sure you want to delete this ${isComment ? 'comment' : 'note'}?`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        try {
          if (isComment) {
            await deleteComment(item.id);
            setComments(comments.filter(comment => comment.id !== item.id));
          } else {
            await deleteNote(item.id);
            setNotes(notes.filter(note => note.id !== item.id));
          }
          message.success(`${isComment ? 'Comment' : 'Note'} deleted successfully`);
        } catch (error) {
          message.error(`Failed to delete ${isComment ? 'comment' : 'note'}`);
        }
      }
    });
  };

  const filteredComments = filterItems(comments);
  const filteredNotes = filterItems(notes);
  const allFilteredItems = [...filteredComments, ...filteredNotes]
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <div style={{ padding: 24 }}>
      <Space 
        direction="vertical" 
        style={{ width: '100%' }}
        size="large"
      >
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={2}>History</Title>
          </Col>
          <Col>
            <Button 
              icon={<ReloadOutlined />} 
              onClick={fetchHistory}
              loading={loading}
            >
              Refresh
            </Button>
          </Col>
        </Row>

        <HistoryStats stats={stats} />

        <SearchFilters
          onSearch={setSearchTerm}
          onDateChange={setDateRange}
          onTypeChange={setSelectedType}
          onDomainChange={setSelectedDomain}
          dateRange={dateRange}
          selectedType={selectedType}
          selectedDomain={selectedDomain}
          domains={domains}
        />

        {loading ? (
          <div style={{ textAlign: 'center', padding: '50px' }}>
            <Spin size="large" />
          </div>
        ) : allFilteredItems.length > 0 ? (
          <List
            itemLayout="vertical"
            dataSource={allFilteredItems}
            renderItem={item => {
              const isComment = !item.content.startsWith('<p>');
              return isComment ? (
                <CommentItem
                  item={item}
                  onDelete={handleDelete}
                />
              ) : (
                <NoteItem
                  item={item}
                  onDelete={handleDelete}
                />
              );
            }}
            pagination={{
              pageSize: 10,
              showTotal: total => `Total ${total} items`
            }}
          />
        ) : (
          <Empty description="No items found" />
        )}
      </Space>
    </div>
  );
};

export default HistoryPage;