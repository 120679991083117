import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Form, Input, Button, message, Typography, Spin } from 'antd';
import { validateInvitation, registerWithInvitation } from '../utils/api';

const { Title, Text } = Typography;

const JoinWorkspacePage = ({ setIsAuthenticated }) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [invitation, setInvitation] = useState(null);
  const [form] = Form.useForm();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    validateInvitationToken();
  }, [token]);

  const validateInvitationToken = async () => {
    try {
      const response = await validateInvitation(token);
      setInvitation(response);
      form.setFieldsValue({ email: response.email });
    } catch (error) {
      message.error('Invalid or expired invitation link');
      navigate('/auth');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    setSubmitting(true);
    try {
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');
      
      // Both new and existing users use registerWithInvitation
      const response = await registerWithInvitation({
        token,
        ...values
      });
      
      localStorage.setItem('token', response.token);
      localStorage.setItem('userData', JSON.stringify(response.user));
      setIsAuthenticated(true);
      
      message.success('Successfully joined workspace');
      navigate('/comments-landing');
    } catch (error) {
      if (error.response?.data?.error) {
        message.error(error.response.data.error);
      } else {
        message.error('Failed to join workspace');
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ maxWidth: '400px', margin: '50px auto', padding: '0 16px' }}>
      <Card>
        <Title level={3} style={{ textAlign: 'center', marginBottom: '24px' }}>
          Join Workspace
        </Title>

        {invitation && (
          <div style={{ marginBottom: '24px', textAlign: 'center' }}>
            <Text>
              You've been invited to join <strong>{invitation.workspaceName}</strong>
            </Text>
          </div>
        )}

        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="email"
            label="Email"
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: 'Please enter your first name' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: 'Please enter your last name' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="jobTitle"
            label="Job Title"
            rules={[{ required: true, message: 'Please enter your job title' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              { required: true, message: 'Please enter a password' },
              { min: 8, message: 'Password must be at least 8 characters' }
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please confirm your password' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              loading={submitting}
              block
            >
              Join Workspace
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default JoinWorkspacePage;