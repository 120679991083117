import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { getUserProfile, updateUserProfile } from '../utils/api';
import defaultAvatar from '../assets/img/default-avatar.png';
import { API_URL } from '../utils/api';

const ProfilePage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const user = await getUserProfile();
      form.setFieldsValue({
        ...user,
        profilePicture: undefined, // Exclude profilePicture
      });
      setProfilePicture(user.profilePicture);
    } catch (error) {
      message.error('Failed to fetch user profile');
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('username', values.username);
      if (values.currentPassword) formData.append('currentPassword', values.currentPassword);
      if (values.newPassword) formData.append('newPassword', values.newPassword);
      
      if (fileList.length > 0) {
        formData.append('profilePicture', fileList[0].originFileObj);
      }

      console.log('FormData contents:');
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }

      const response = await updateUserProfile(formData);
      message.success('Profile updated successfully');
      setProfilePicture(response.profilePicture);
      form.resetFields(['currentPassword', 'newPassword', 'confirmNewPassword']);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        message.error(error.response.data.error);
      } else {
        message.error('Failed to update profile');
      }
    } finally {
      setLoading(false);
    }
  };

  const getAvatarSrc = () => {
    if (profilePicture) {
      return `${API_URL}/uploads/profile-pictures/${form.getFieldValue('id')}/${profilePicture}`;
    }
    return defaultAvatar;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileList([{ originFileObj: file }]);
    } else {
      setFileList([]);
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '50px auto' }}>
      <h2>User Profile</h2>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item>
          <Avatar 
            size={88} 
            icon={<UserOutlined />} 
            src={getAvatarSrc()} 
          />
        </Form.Item>
        <Form.Item label="Profile Picture">
          <input type="file" onChange={handleFileChange} accept="image/*" />
        </Form.Item>
        <Form.Item name="username" label="Username" rules={[{ required: true }]}>
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input placeholder="Email" disabled />
        </Form.Item>
        <Form.Item name="currentPassword" label="Current Password" hasFeedback>
          <Input.Password placeholder="Enter current password to change password" />
        </Form.Item>
        <Form.Item name="newPassword" label="New Password" dependencies={['currentPassword']} hasFeedback>
          <Input.Password placeholder="New Password" />
        </Form.Item>
        <Form.Item
          name="confirmNewPassword"
          label="Confirm New Password"
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords do not match!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm New Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save Profile
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProfilePage;