import React, { useState, useEffect } from 'react';
import { 
  Card,
  Button,
  Space,
  Typography,
  message,
  Alert,
  Spin,
  Divider,
} from 'antd';
import {
  SlackOutlined,
  LinkOutlined,
  DisconnectOutlined
} from '@ant-design/icons';
import { useWorkspace } from '../components/WorkspaceContext';
import { API_URL } from '../utils/api';

const { Title, Text, Paragraph } = Typography;

const BOT_SCOPES = ['incoming-webhook'].join(',');

const SlackIntegration = () => {
  const { activeWorkspace } = useWorkspace();
  const [connected, setConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [slackWorkspace, setSlackWorkspace] = useState(null);

  const logIntegrationEvent = (event, data) => {
    console.log(`[Slack Frontend] ${event}:`, {
      timestamp: new Date().toISOString(),
      ...data
    });
  };

  useEffect(() => {
    // Handle OAuth callback
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const error = urlParams.get('error');

    logIntegrationEvent('Checking URL parameters', { code: !!code, error });

    if (error) {
      logIntegrationEvent('OAuth error from Slack', { error });
      setError(`Slack connection failed: ${error}`);
      window.history.replaceState({}, document.title, window.location.pathname);
      return;
    }

    if (code) {
      logIntegrationEvent('OAuth code received', { codeLength: code.length });
      handleOAuthCallback(code);
      window.history.replaceState({}, document.title, window.location.pathname);
    } else {
      checkSlackConnection();
    }
  }, []);

  const checkSlackConnection = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}slack/status`, {
        headers: {
          'Authorization': localStorage.getItem('token')
        }
      });
      const data = await response.json();
      setConnected(data.connected);
      setSlackWorkspace(data.workspace);
    } catch (err) {
      setError('Failed to check Slack connection status');
    } finally {
      setLoading(false);
    }
  };

  const handleConnect = () => {
    try {
      const clientId = process.env.REACT_APP_SLACK_CLIENT_ID;
      const redirectUri = encodeURIComponent(`${window.location.origin}/settings/integrations/slack`);
      const slackUrl = `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=${BOT_SCOPES}&redirect_uri=${redirectUri}`;
      
      logIntegrationEvent('Initiating Slack connection', {
        clientId,
        redirectUri,
        scopes: BOT_SCOPES
      });

      window.location.href = slackUrl;
    } catch (error) {
      logIntegrationEvent('Connection initiation error', {
        error: error.message
      });
      setError('Failed to initiate Slack connection');
    }
  };

  const handleDisconnect = async () => {
    try {
      setLoading(true);
      await fetch(`${API_URL}slack/disconnect`, {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('token')
        }
      });
      setConnected(false);
      setSlackWorkspace(null);
      message.success('Disconnected from Slack successfully');
    } catch (error) {
      message.error('Failed to disconnect from Slack');
    } finally {
      setLoading(false);
    }
  };

  const handleOAuthCallback = async (code) => {
    try {
      setLoading(true);
      logIntegrationEvent('Starting OAuth callback handling', { code: code.substring(0, 4) + '...' });

      const response = await fetch(`${API_URL}slack/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        body: JSON.stringify({ code })
      });

      logIntegrationEvent('OAuth callback response', { 
        status: response.status,
        ok: response.ok 
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to connect to Slack');
      }

      const data = await response.json();
      logIntegrationEvent('Connection successful', {
        teamName: data.teamName
      });

      setConnected(true);
      setSlackWorkspace(data.workspace);
      message.success('Successfully connected to Slack!');
    } catch (error) {
      logIntegrationEvent('OAuth callback error', {
        error: error.message,
        stack: error.stack
      });
      setError(error.message);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!activeWorkspace) {
    return (
      <div style={{ padding: '24px', textAlign: 'center' }}>
        <Title level={4}>Please select a workspace first</Title>
      </div>
    );
  }

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: '24px' }}>
      <Title level={2}>Slack Integration</Title>
      
      {error && (
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
          style={{ marginBottom: '24px' }}
          closable
          onClose={() => setError(null)}
        />
      )}

      <Card>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Space align="start">
            <SlackOutlined style={{ fontSize: '24px', color: '#36C5F0' }} />
            <div>
              <Text strong style={{ fontSize: '16px', display: 'block' }}>
                {connected ? 'Connected to Slack' : 'Connect to Slack'}
              </Text>
              <Paragraph type="secondary">
                {connected 
                  ? `Connected to ${slackWorkspace?.teamName}. Comments will be posted to your selected channels.`
                  : 'Connect Comment Fusion to post comments to your Slack channels.'}
              </Paragraph>
            </div>
          </Space>

          {connected && (
            <>
              <Alert
                message="Integration Capabilities"
                description={
                  <ul style={{ marginBottom: 0, paddingLeft: 20 }}>
                    <li>Post comments to selected Slack channels</li>
                    <li>Configure which comments are shared</li>
                    <li>Customize notification format for each channel</li>
                  </ul>
                }
                type="info"
                showIcon
              />
              <Divider />
            </>
          )}

          <Space>
            <Button
              type={connected ? 'default' : 'primary'}
              icon={<LinkOutlined />}
              onClick={handleConnect}
              disabled={loading}
            >
              {connected ? 'Reconnect to Slack' : 'Connect to Slack'}
            </Button>

            {connected && (
              <Button
                danger
                icon={<DisconnectOutlined />}
                onClick={handleDisconnect}
                disabled={loading}
              >
                Disconnect from Slack
              </Button>
            )}
          </Space>
        </Space>
      </Card>
    </div>
  );
};

export default SlackIntegration;