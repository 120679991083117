import React, { useState } from "react";
import { Tabs, Card, Typography } from "antd";
import { LoginForm } from "./LoginForm";
import { RegisterForm } from "./RegisterForm";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const AuthPage = ({ setIsAuthenticated, initialTab = "login" }) => {
  const [activeTab, setActiveTab] = useState(initialTab);
  const navigate = useNavigate();

  const handleAuthSuccess = () => {
    setIsAuthenticated(true);
    navigate("/dashboard");
  };
  
  const items = [
    {
      key: 'login',
      label: 'Login',
      children: <LoginForm onSuccess={handleAuthSuccess} />,
    },
    {
      key: 'register',
      label: 'Register',
      children: <RegisterForm onSuccess={() => setActiveTab("login")} setActiveTab={setActiveTab} />,
    },
  ];

  return (
    <div style={{ 
      maxWidth: "400px", 
      margin: "50px auto",
      padding: "0 16px"
    }}>
      <Card>
        <Title level={3} style={{ textAlign: 'center', marginBottom: '24px' }}>
          {activeTab === 'login' ? 'Welcome Back' : 'Create Account'}
        </Title>
        
        <Tabs 
          activeKey={activeTab} 
          onChange={setActiveTab} 
          items={items}
          centered
        />
      </Card>
    </div>
  );
};

export default AuthPage;