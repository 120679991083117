import React from 'react';
import { List } from 'antd';
import { Comment } from './Comment';

export const CommentList = ({ 
  comments, 
  addReply, 
  onVote, 
  isAuthenticated, 
  onEdit, 
  onDelete,
  onUpdateComment 
}) => {
  const handleReplyError = (error) => {
    if (error.response?.status === 400) {
      message.error(error.response.data.error);
    } else {
      message.error("Failed to add reply. Please try again.");
    }
  };

  return (
    <List
      className="comment-list"
      itemLayout="horizontal"
      dataSource={comments}
      renderItem={(item) => (
        <List.Item>
          <Comment
            comment={item}
            addReply={addReply}
            onVote={onVote}
            isAuthenticated={isAuthenticated}
            onEdit={onEdit}
            onDelete={onDelete}
            onUpdateComment={onUpdateComment}
            onError={handleReplyError}
          />
        </List.Item>
      )}
    />
  );
};