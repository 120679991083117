import React, { useState } from "react";
import { Form, Button } from "antd";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const NoteForm = ({ onSubmit, initialContent = '' }) => {
  const [content, setContent] = useState(initialContent);

  const handleSubmit = () => {
    onSubmit(content);
    setContent('');
  };

  return (
    <Form onFinish={handleSubmit}>
      <Form.Item>
        <ReactQuill
          theme="snow"
          value={content}
          onChange={setContent}
          placeholder="Write your note..."
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          {initialContent ? 'Update Note' : 'Add Note'}
        </Button>
      </Form.Item>
    </Form>
  );
};