import React, { useState } from 'react';
import {
  Modal,
  Steps,
  Form,
  Input,
  Button,
  Radio,
  Space,
  Typography,
  Select,
  Divider,
  message
} from 'antd';
import { useWorkspace } from './WorkspaceContext';

const { Step } = Steps;
const { Text } = Typography;
const { TextArea } = Input;

const CreateWorkspaceModal = ({ visible, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const { createWorkspace } = useWorkspace();
  const [loading, setLoading] = useState(false);

  const steps = [
    {
      title: 'Workspace Details',
      content: (
        <Form.Item 
          name="name" 
          rules={[
            { required: true, message: 'Please enter a workspace name' },
            { min: 3, message: 'Name must be at least 3 characters' }
          ]}
        >
          <Input 
            size="large"
            placeholder="Enter workspace name"
            maxLength={50}
          />
        </Form.Item>
      )
    },
    {
      title: 'Access Control',
      content: (
        <>
          <Form.Item 
            name="accessType"
            initialValue="open"
          >
            <Radio.Group>
              <Space direction="vertical" size="large">
                <Radio value="open">
                  <Space direction="vertical">
                    <Text strong>Open Workspace</Text>
                    <Text type="secondary">Anyone can join this workspace</Text>
                  </Space>
                </Radio>
                
                <Radio value="invite">
                  <Space direction="vertical">
                    <Text strong>Invite-only Workspace</Text>
                    <Text type="secondary">Members can only join with an invitation</Text>
                  </Space>
                </Radio>
                
                <Radio value="domain">
                  <Space direction="vertical">
                    <Text strong>Domain-restricted Workspace</Text>
                    <Text type="secondary">Only people with specific email domains can join</Text>
                  </Space>
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => 
              prevValues.accessType !== currentValues.accessType
            }
          >
            {({ getFieldValue }) => 
              getFieldValue('accessType') === 'domain' && (
                <Form.Item
                  name="allowedDomains"
                  label="Allowed Email Domains"
                  rules={[{ required: true, message: 'Please enter at least one domain' }]}
                >
                  <Select
                    mode="tags"
                    placeholder="Enter domains (e.g., company.com)"
                    tokenSeparators={[',']}
                  />
                </Form.Item>
              )
            }
          </Form.Item>
        </>
      )
    },
    {
      title: 'Invite Members',
      content: (
        <Form.Item name="invites">
          <TextArea
            placeholder="Enter email addresses (one per line)"
            rows={4}
          />
        </Form.Item>
      )
    }
  ];

  const handleNext = () => {
    form.validateFields().then(() => {
      setCurrentStep(currentStep + 1);
    });
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      
      const workspaceData = {
        name: values.name,
        enforceDomainRestriction: values.accessType === 'domain',
        allowedDomains: values.accessType === 'domain' ? values.allowedDomains : [],
        inviteOnly: values.accessType === 'invite'
      };
  
      await createWorkspace(workspaceData);
      message.success('Workspace created successfully!');
      onClose();
      form.resetFields();
    } catch (error) {
      if (!error.isValidationError) {
        message.error('Failed to create workspace');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Create New Workspace"
      open={visible}
      onCancel={onClose}
      width={640}
      footer={null}
    >
      <Steps current={currentStep} style={{ marginBottom: 24 }}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
      >
        <div style={{ minHeight: 200 }}>
          {steps.map((step, index) => (
            <div
              key={step.title}
              style={{ display: index === currentStep ? 'block' : 'none' }}
            >
              {step.content}
            </div>
          ))}
        </div>

        <Divider />

        <div style={{ textAlign: 'right' }}>
          {currentStep > 0 && (
            <Button style={{ marginRight: 8 }} onClick={handleBack}>
              Back
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={handleNext}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button 
              type="primary" 
              onClick={handleSubmit}
              loading={loading}
            >
              Create Workspace
            </Button>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default CreateWorkspaceModal;