import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Switch,
  Space,
  Tag,
  message,
  Typography,
  Tooltip,
  Select
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  UsergroupAddOutlined,
  GlobalOutlined,
  LockOutlined
} from '@ant-design/icons';
import { 
  getChannels,
  createChannel,
  updateChannel,
  deleteChannel
} from '../utils/api';

const { Title } = Typography;
const { Option } = Select;

const ChannelManagement = () => {
  const [channels, setChannels] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingChannel, setEditingChannel] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchChannels();
  }, []);

  const fetchChannels = async () => {
    try {
      setLoading(true);
      const response = await getChannels();
      setChannels(response);
    } catch (error) {
      message.error('Failed to fetch channels');
    } finally {
      setLoading(false);
    }
  };

  const getScopeTag = (scope) => {
    switch (scope) {
      case 'workspace':
        return <Tag color="purple">Workspace</Tag>;
      case 'domain':
        return <Tag color="blue">Domain</Tag>;
      case 'url':
        return <Tag color="green">URL</Tag>;
      default:
        return null;
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Space>
          {record.isPublic ? <GlobalOutlined /> : <LockOutlined />}
          {text}
        </Space>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true
    },
    {
      title: 'Type',
      key: 'type',
      render: (_, record) => (
        <Space>
          <Tag color={record.isPublic ? 'green' : 'red'}>
            {record.isPublic ? 'Public' : 'Private'}
          </Tag>
          {getScopeTag(record.scope)}
        </Space>
      )
    },
    {
      title: 'Members',
      key: 'members',
      render: (_, record) => (
        <Space>
          <UsergroupAddOutlined />
          {record.Users?.length || 0}
        </Space>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit Channel">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setEditingChannel(record);
                form.setFieldsValue({
                  ...record,
                  scope: record.scope || 'url' // Default to URL scope if not set
                });
                setModalVisible(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete Channel">
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: 'Delete Channel',
                  content: 'Are you sure you want to delete this channel?',
                  okText: 'Delete',
                  okType: 'danger',
                  onOk: () => handleDelete(record.id)
                });
              }}
            />
          </Tooltip>
        </Space>
      )
    }
  ];

  const handleDelete = async (channelId) => {
    try {
      await deleteChannel(channelId);
      message.success('Channel deleted successfully');
      fetchChannels();
    } catch (error) {
      message.error('Failed to delete channel');
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (editingChannel) {
        await updateChannel(editingChannel.id, values);
        message.success('Channel updated successfully');
      } else {
        await createChannel(values);
        message.success('Channel created successfully');
      }
      setModalVisible(false);
      form.resetFields();
      fetchChannels();
    } catch (error) {
      if (error.response?.data?.error) {
        message.error(error.response.data.error);
      } else {
        message.error('Operation failed');
      }
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <Title level={2}>Channel Management</Title>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setEditingChannel(null);
            form.resetFields();
            setModalVisible(true);
          }}
        >
          New Channel
        </Button>
      </div>

      <Table
        loading={loading}
        columns={columns}
        dataSource={channels}
        rowKey="id"
      />

      <Modal
        title={editingChannel ? 'Edit Channel' : 'New Channel'}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingChannel(null);
          form.resetFields();
        }}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            isPublic: true,
            scope: 'url'
          }}
        >
          <Form.Item
            name="name"
            label="Channel Name"
            rules={[
              { required: true, message: 'Please enter channel name' },
              { pattern: /^[a-z0-9-]+$/, message: 'Only lowercase letters, numbers, and hyphens are allowed' }
            ]}
            tooltip="Channel names should be lowercase with hyphens, e.g., 'project-discussion'"
          >
            <Input placeholder="e.g., project-discussion" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
          >
            <Input.TextArea rows={4} placeholder="What's this channel about?" />
          </Form.Item>

          <Form.Item
            name="isPublic"
            label="Visibility"
            valuePropName="checked"
          >
            <Switch 
              checkedChildren="Public" 
              unCheckedChildren="Private" 
            />
          </Form.Item>

          <Form.Item
            name="scope"
            label="Channel Scope"
            tooltip="Determine where this channel will be available"
          >
            <Select>
              <Option value="url">URL only (specific page)</Option>
              <Option value="domain">Domain-wide (entire website)</Option>
              <Option value="workspace">Workspace-wide (all pages)</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="domainPattern"
            label="Pattern"
            tooltip="The URL or domain this channel applies to"
            rules={[
              { required: true, message: 'Please enter a pattern' }
            ]}
          >
            <Input placeholder="Enter URL or domain pattern" />
          </Form.Item>

          <Form.Item className="mb-0 text-right">
            <Space>
              <Button onClick={() => setModalVisible(false)}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                {editingChannel ? 'Update' : 'Create'} Channel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ChannelManagement;