import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Statistic,
  Table,
  Space,
  Typography,
  Tag,
  Alert,
  Spin,
  Tabs,
  Avatar,
  Button,
  Row,
  Col,
  Progress,
  Badge,
  List,
} from 'antd';
import {
  UserOutlined,
  TeamOutlined,
  MessageOutlined,
  DashboardOutlined,
  UsergroupAddOutlined,
  GlobalOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  CalendarOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { useWorkspace } from '../components/WorkspaceContext';
import {
  getWorkspaceMembers,
  getChannels,
  getComments,
  getAllUsers,
  updateUserStatus,
  getDomainRestrictions,
} from '../utils/api';
import UserStatusManagement from '../components/UserStatusManagement';

const { Title, Text } = Typography;

const AdminDashboard = () => {
  const { activeWorkspace } = useWorkspace();
  const [activeTab, setActiveTab] = useState('1');
  const [initialLoading, setInitialLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // State for dashboard data
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    totalChannels: 0,
    totalComments: 0,
    commentsToday: 0,
    commentsTrend: 0,
  });
  const [users, setUsers] = useState([]);
  const [recentActivity, setRecentActivity] = useState([]);
  const [domainStats, setDomainStats] = useState([]);

  useEffect(() => {
    if (activeWorkspace) {
      fetchDashboardData(true);
    }
  }, [activeWorkspace]);

  const fetchDashboardData = async (isInitial = false) => {
    if (isInitial) {
      setInitialLoading(true);
    } else {
      setRefreshing(true);
    }

    try {
      // Check user role before making admin-only API calls
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');
      const workspace = userData.workspaces?.find((w) => w.isActive);

      if (workspace?.role !== 'admin') {
        navigate('/comments-landing');
        return;
      }

      const [members, channels, comments, allUsers, domains] = await Promise.all([
        getWorkspaceMembers(activeWorkspace.id),
        getChannels(),
        getComments(),
        getAllUsers(),
        getDomainRestrictions(),
      ]);

      // Process users data
      setUsers(allUsers);

      // Calculate statistics
      const activeUsers = allUsers.filter((user) => user.status === 'active').length;
      const today = new Date().toDateString();
      const yesterday = new Date(Date.now() - 86400000).toDateString();

      const commentsToday = comments.filter(
        (comment) => new Date(comment.createdAt).toDateString() === today
      ).length;

      const commentsYesterday = comments.filter(
        (comment) => new Date(comment.createdAt).toDateString() === yesterday
      ).length;

      const commentsTrend = commentsYesterday
        ? ((commentsToday - commentsYesterday) / commentsYesterday) * 100
        : 100;

      setStats({
        totalUsers: allUsers.length,
        activeUsers,
        totalChannels: channels.length,
        totalComments: comments.length,
        commentsToday,
        commentsTrend,
      });

      // Generate recent activity
      const activity = [
        ...members.slice(-5).map((member) => ({
          id: `user-${member.id}`,
          type: 'user_joined',
          user: member.username,
          timestamp: member.createdAt,
          details: 'New member joined',
        })),
        ...channels.slice(-5).map((channel) => ({
          id: `channel-${channel.id}`,
          type: 'channel_created',
          user: channel.createdBy,
          timestamp: channel.createdAt,
          details: `New channel "${channel.name}" created`,
        })),
        ...comments.slice(-5).map((comment) => ({
          id: `comment-${comment.id}`,
          type: 'comment_added',
          user: comment.User?.username || 'Anonymous',
          timestamp: comment.createdAt,
          details: `New comment on ${new URL(comment.url).hostname}`,
        })),
      ]
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
        .slice(0, 10);

      setRecentActivity(activity);

      // Process domain statistics
      const domainMetrics = processDomainStatistics(comments, domains);
      setDomainStats(domainMetrics);

      setError(null);
    } catch (err) {
      setError('Failed to fetch dashboard data. Please try again later.');
      console.error('Dashboard data fetch error:', err);
    } finally {
      setInitialLoading(false);
      setRefreshing(false);
    }
  };

  const processDomainStatistics = (comments, domains) => {
    const domainMetrics = {};

    comments.forEach((comment) => {
      const domain = new URL(comment.url).hostname;
      if (!domainMetrics[domain]) {
        domainMetrics[domain] = {
          domain,
          commentCount: 0,
          userCount: new Set(),
          engagement: 0,
          restricted: domains.includes(domain),
        };
      }

      domainMetrics[domain].commentCount++;
      domainMetrics[domain].userCount.add(comment.userId);
    });

    return Object.values(domainMetrics).map((metric) => ({
      ...metric,
      userCount: metric.userCount.size,
      engagement: Math.round((metric.commentCount / metric.userCount.size) * 100),
    }));
  };

  const handleStatusUpdate = async (userId, status) => {
    try {
      await updateUserStatus(userId, status);
      // Only refresh the users data instead of everything
      const allUsers = await getAllUsers();
      setUsers(allUsers);
      
      // Update active users count in stats
      const activeUsers = allUsers.filter((user) => user.status === 'active').length;
      setStats(prev => ({
        ...prev,
        totalUsers: allUsers.length,
        activeUsers
      }));
    } catch (err) {
      setError('Failed to update user status. Please try again.');
    }
  };

  if (!activeWorkspace) {
    return (
      <div style={{ padding: '24px', textAlign: 'center' }}>
        <Title level={4}>Please select a workspace first</Title>
      </div>
    );
  }

  if (initialLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const renderOverviewTab = () => (
    <div style={{ position: 'relative' }}>
      {refreshing && (
        <div style={{ position: 'absolute', top: 0, right: 0, padding: '8px' }}>
          <Spin />
        </div>
      )}
      <Row gutter={[16, 16]} className="mb-6">
        <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title="Total Members"
              value={stats.totalUsers}
              prefix={<UserOutlined style={{ color: '#1890ff' }} />}
              suffix={
                <Progress
                  percent={Math.round((stats.activeUsers / stats.totalUsers) * 100)}
                  size="small"
                  style={{ marginTop: 8 }}
                />
              }
            />
          </Card>
        </Col>

        <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title="Total Channels"
              value={stats.totalChannels}
              prefix={<TeamOutlined style={{ color: '#52c41a' }} />}
            />
          </Card>
        </Col>

        <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title="Comments Today"
              value={stats.commentsToday}
              prefix={<MessageOutlined style={{ color: '#722ed1' }} />}
              suffix={
                <Tag color={stats.commentsTrend >= 0 ? 'success' : 'error'}>
                  {stats.commentsTrend >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                  {Math.abs(Math.round(stats.commentsTrend))}%
                </Tag>
              }
            />
          </Card>
        </Col>

        <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title="Active Users"
              value={stats.activeUsers}
              prefix={<TeamOutlined style={{ color: '#13c2c2' }} />}
              suffix={`/ ${stats.totalUsers}`}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Card
            title={
              <Space>
                <CalendarOutlined />
                Recent Activity
              </Space>
            }
          >
            <List
              dataSource={recentActivity}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        icon={
                          item.type === 'user_joined' ? (
                            <UserOutlined />
                          ) : item.type === 'channel_created' ? (
                            <TeamOutlined />
                          ) : (
                            <MessageOutlined />
                          )
                        }
                      />
                    }
                    title={item.details}
                    description={
                      <Space>
                        <Text type="secondary">by {item.user}</Text>
                        <Text type="secondary">•</Text>
                        <Text type="secondary">
                          {new Date(item.timestamp).toLocaleString()}
                        </Text>
                      </Space>
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>

        <Col xs={24} md={12}>
          <Card
            title={
              <Space>
                <GlobalOutlined />
                Domain Activity
              </Space>
            }
          >
            <Table
              dataSource={domainStats}
              columns={[
                {
                  title: 'Domain',
                  dataIndex: 'domain',
                  key: 'domain',
                  render: (domain) => (
                    <Space>
                      <GlobalOutlined />
                      {domain}
                    </Space>
                  ),
                },
                {
                  title: 'Comments',
                  dataIndex: 'commentCount',
                  key: 'commentCount',
                  render: (count) => (
                    <Badge
                      count={count}
                      overflowCount={999}
                      style={{ backgroundColor: '#52c41a' }}
                    />
                  ),
                },
                {
                  title: 'Users',
                  dataIndex: 'userCount',
                  key: 'userCount',
                },
                {
                  title: 'Engagement',
                  dataIndex: 'engagement',
                  key: 'engagement',
                  render: (value) => <Progress percent={value} size="small" />,
                },
              ]}
              pagination={false}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );

  // Define the tab items
  const tabItems = [
    {
      key: '1',
      label: (
        <span>
          <DashboardOutlined />
          Overview
        </span>
      ),
      children: renderOverviewTab()
    },
    {
      key: '2',
      label: (
        <span>
          <UsergroupAddOutlined />
          User Management
        </span>
      ),
      children: (
        <div style={{ position: 'relative' }}>
          {refreshing && (
            <div style={{ position: 'absolute', top: 0, right: 0, padding: '8px' }}>
              <Spin />
            </div>
          )}
          <UserStatusManagement
            users={users}
            onStatusUpdate={handleStatusUpdate}
          />
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <span>
          <GlobalOutlined />
          Domain Access
        </span>
      ),
      children: (
        <div style={{ position: 'relative' }}>
          {refreshing && (
            <div style={{ position: 'absolute', top: 0, right: 0, padding: '8px' }}>
              <Spin />
            </div>
          )}
          <Card title="Domain Access Control">
            <Table
              dataSource={domainStats}
              columns={[
                {
                  title: 'Domain',
                  dataIndex: 'domain',
                  key: 'domain',
                  render: (domain) => (
                    <Space>
                      <GlobalOutlined />
                      {domain}
                    </Space>
                  ),
                },
                {
                  title: 'Status',
                  key: 'status',
                  render: (_, record) => (
                    <Tag
                      icon={record.restricted ? <LockOutlined /> : <UnlockOutlined />}
                      color={record.restricted ? 'error' : 'success'}
                    >
                      {record.restricted ? 'Restricted' : 'Allowed'}
                    </Tag>
                  ),
                },
                {
                  title: 'Activity',
                  key: 'activity',
                  render: (_, record) => (
                    <Space direction="vertical" size="small" style={{ width: '100%' }}>
                      <Text>{record.commentCount} comments</Text>
                      <Progress percent={record.engagement} size="small" />
                    </Space>
                  ),
                },
                {
                  title: 'Actions',
                  key: 'actions',
                  render: (_, record) => (
                    <Space>
                      <Button type={record.restricted ? 'primary' : 'default'}>
                        {record.restricted ? 'Allow' : 'Restrict'}
                      </Button>
                    </Space>
                  ),
                },
              ]}
            />
          </Card>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6">
      {error && (
        <Alert message="Error" description={error} type="error" showIcon className="mb-6" />
      )}

      <Tabs
        activeKey={activeTab}
        onChange={setActiveTab}
        items={tabItems}
        destroyInactiveTabPane={false}
      />
    </div>
  );
};

export default AdminDashboard;