import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { requestPasswordReset } from '../utils/api';

const RequestResetPage = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await requestPasswordReset(values.email);
      message.success('Password reset email sent. Please check your inbox.');
    } catch (error) {
      message.error('Failed to send reset email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '50px auto' }}>
      <h2>Reset Password</h2>
      <Form onFinish={handleSubmit}>
        <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Request Password Reset
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RequestResetPage;