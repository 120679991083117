import React, { useState } from 'react';
import { List, Typography, Space, Button, Avatar } from 'antd';
import { DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import { deleteNote, updateNote } from '../utils/api';
import { NoteForm } from './NoteForm';
import { API_URL } from '../utils/api';

const { Text } = Typography;

export const NoteList = ({ notes, setNotes }) => {
  const [editingNoteId, setEditingNoteId] = useState(null);

  const handleDelete = async (id) => {
    try {
      await deleteNote(id);
      setNotes(notes.filter(note => note.id !== id));
    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };

  const handleEdit = async (id, content) => {
    try {
      const updatedNote = await updateNote(id, content);
      setNotes(notes.map(note => note.id === id ? updatedNote : note));
      setEditingNoteId(null);
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };

  return (
    <List
      className="note-list"
      itemLayout="vertical"
      dataSource={notes}
      renderItem={(note) => (
        <List.Item
          actions={[
            <Button icon={<EditOutlined />} onClick={() => setEditingNoteId(note.id)}>Edit</Button>,
            <Button icon={<DeleteOutlined />} onClick={() => handleDelete(note.id)}>Delete</Button>
          ]}
        >
          <Space direction="vertical">
            <Space>
              <Avatar icon={<UserOutlined />} src={note.User?.profilePictureUrl} />
              <Text strong>{note.User?.username || "Anonymous"}</Text>
            </Space>
            {editingNoteId === note.id ? (
              <NoteForm onSubmit={(content) => handleEdit(note.id, content)} initialContent={note.content} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: note.content }} />
            )}
            <Text type="secondary">{new Date(note.createdAt).toLocaleString()}</Text>
          </Space>
        </List.Item>
      )}
    />
  );
};