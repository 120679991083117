import React, { createContext, useContext, useState, useEffect } from 'react';
import { message } from 'antd';
import {
  getWorkspaces,
  createWorkspace,
  updateWorkspace,
  deleteWorkspace,
  switchWorkspace,
  inviteToWorkspace
} from '../utils/api';

const WorkspaceContext = createContext();

export const WorkspaceProvider = ({ children }) => {
  const [workspaces, setWorkspaces] = useState([]);
  const [activeWorkspace, setActiveWorkspace] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      initializeWorkspaces();
    } else {
      setLoading(false);
      setInitialized(true);
    }
  }, []);

  const initializeWorkspaces = async () => {
    try {
      setLoading(true);
      const response = await getWorkspaces();
      setWorkspaces(response);
      
      // Get active workspace from user data
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');
      const activeWorkspaceId = userData.activeWorkspace?.id;
      
      if (activeWorkspaceId && response.find(w => w.id === activeWorkspaceId)) {
        setActiveWorkspace(response.find(w => w.id === activeWorkspaceId));
      } else if (response.length > 0) {
        // If no active workspace is set but workspaces exist, set the first one as active
        const firstWorkspace = response[0];
        await handleSwitchWorkspace(firstWorkspace.id);
      }
    } catch (error) {
      console.error('Failed to initialize workspaces:', error);
      message.error('Failed to load workspaces');
    } finally {
      setLoading(false);
      setInitialized(true);
    }
  };

  const handleSwitchWorkspace = async (workspaceId) => {
    try {
      const response = await switchWorkspace(workspaceId);
      
      // Update token in localStorage
      localStorage.setItem('token', response.token);
      
      // Update user data in localStorage
      const userData = response.user;
      localStorage.setItem('userData', JSON.stringify(userData));
  
      // Update workspaces and activeWorkspace in state
      setWorkspaces(userData.workspaces);
      setActiveWorkspace(userData.activeWorkspace);
  
      message.success(`Switched to ${userData.activeWorkspace.name}`);
      return true;
    } catch (error) {
      message.error('Failed to switch workspace');
      return false;
    }
  };
  

  const handleCreateWorkspace = async (values) => {
    try {
      if (!values.name) {
        throw new Error('Workspace name is required');
      }
  
      const newWorkspace = await createWorkspace(values);
      setWorkspaces(prev => [...prev, newWorkspace]);
      await handleSwitchWorkspace(newWorkspace.id);
      
      message.success(`Created workspace: ${newWorkspace.name}`);
      return newWorkspace;
    } catch (error) {
      if (error.response?.data?.error) {
        message.error(error.response.data.error);
      } else {
        message.error(error.message || 'Failed to create workspace');
      }
      throw error;
    }
  };

  const handleUpdateWorkspace = async (workspaceId, values) => {
    try {
      const updatedWorkspace = await updateWorkspace(workspaceId, values);
      
      setWorkspaces(prev => 
        prev.map(workspace => 
          workspace.id === workspaceId ? updatedWorkspace : workspace
        )
      );
      
      if (activeWorkspace?.id === workspaceId) {
        setActiveWorkspace(updatedWorkspace);
      }
      
      message.success('Workspace updated successfully');
      return updatedWorkspace;
    } catch (error) {
      message.error('Failed to update workspace');
      throw error;
    }
  };

  const handleDeleteWorkspace = async (workspaceId) => {
    try {
      await deleteWorkspace(workspaceId);
      
      setWorkspaces(prev => prev.filter(w => w.id !== workspaceId));
      
      if (activeWorkspace?.id === workspaceId) {
        const nextWorkspace = workspaces.find(w => w.id !== workspaceId);
        if (nextWorkspace) {
          await handleSwitchWorkspace(nextWorkspace.id);
        } else {
          setActiveWorkspace(null);
        }
      }
      
      message.success('Workspace deleted successfully');
    } catch (error) {
      message.error('Failed to delete workspace');
      throw error;
    }
  };

  const handleInviteMember = async (workspaceId, email, role = 'member') => {
    try {
      const response = await inviteToWorkspace(workspaceId, email, role);
      message.success('Invitation sent successfully');
      return response;
    } catch (error) {
      message.error('Failed to send invitation');
      throw error;
    }
  };

  const value = {
    workspaces,
    activeWorkspace,
    loading,
    initialized,
    switchWorkspace: handleSwitchWorkspace,
    createWorkspace: handleCreateWorkspace,
    updateWorkspace: handleUpdateWorkspace,
    deleteWorkspace: handleDeleteWorkspace,
    inviteMember: handleInviteMember,
    refreshWorkspaces: initializeWorkspaces
  };

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspace = () => {
  const context = useContext(WorkspaceContext);
  if (!context) {
    throw new Error('useWorkspace must be used within a WorkspaceProvider');
  }
  return context;
};

export default WorkspaceContext;