import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Switch,
  Space,
  Tag,
  message,
  Typography,
  Tooltip,
  Card,
  Alert
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  StopOutlined,
  InfoCircleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined
} from '@ant-design/icons';
import { useWorkspace } from '../components/WorkspaceContext';
import {
  getDomainRestrictions,
  addDomainRestriction,
  updateDomainRestriction,
  deleteDomainRestriction
} from '../utils/api';

const { Title, Text } = Typography;

const DomainRestrictions = () => {
  const { activeWorkspace } = useWorkspace();
  const [restrictions, setRestrictions] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingRestriction, setEditingRestriction] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (activeWorkspace) {
      fetchRestrictions();
    }
  }, [activeWorkspace]);

  const fetchRestrictions = async () => {
    setLoading(true);
    try {
      const data = await getDomainRestrictions();
      setRestrictions(data);
    } catch (error) {
      message.error('Failed to fetch domain restrictions');
    } finally {
      setLoading(false);
    }
  };

  const handleAddEdit = async (values) => {
    try {
      if (editingRestriction) {
        await updateDomainRestriction(editingRestriction.id, values);
        message.success('Domain restriction updated successfully');
      } else {
        await addDomainRestriction(values);
        message.success('Domain restriction created successfully');
      }
      setModalVisible(false);
      form.resetFields();
      fetchRestrictions();
    } catch (error) {
      message.error('Operation failed');
    }
  };

  const handleDelete = async (restrictionId) => {
    try {
      await deleteDomainRestriction(restrictionId);
      message.success('Domain restriction deleted successfully');
      fetchRestrictions();
    } catch (error) {
      message.error('Failed to delete domain restriction');
    }
  };

  const columns = [
    {
      title: () => (
        <Space>
          Domain Pattern
          <Tooltip title="Use *.example.com for wildcards or example.com for exact matches">
            <InfoCircleOutlined style={{ color: '#1890ff' }} />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'pattern',
      key: 'pattern',
      render: (text) => (
        <Text copyable>{text}</Text>
      )
    },
    {
      title: 'Access',
      key: 'access',
      render: (_, record) => (
        <Tag color={record.isAllowed ? 'green' : 'red'} style={{ width: '90px', textAlign: 'center' }}>
          {record.isAllowed ? (
            <Space>
              <CheckCircleOutlined />
              Allowed
            </Space>
          ) : (
            <Space>
              <StopOutlined />
              Blocked
            </Space>
          )}
        </Tag>
      )
    },
    {
      title: () => (
        <Space>
          Priority
          <Tooltip title="Higher priority rules are evaluated first">
            <InfoCircleOutlined style={{ color: '#1890ff' }} />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'priority',
      key: 'priority',
      sorter: (a, b) => a.priority - b.priority,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit Rule">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setEditingRestriction(record);
                form.setFieldsValue(record);
                setModalVisible(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete Rule">
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: 'Delete Domain Restriction',
                  content: 'Are you sure you want to delete this domain restriction?',
                  okText: 'Delete',
                  okType: 'danger',
                  onOk: () => handleDelete(record.id)
                });
              }}
            />
          </Tooltip>
        </Space>
      )
    }
  ];

  if (!activeWorkspace) {
    return (
      <div style={{ padding: '24px', textAlign: 'center' }}>
        <Title level={4}>Please select a workspace first</Title>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title level={2}>Domain Restrictions</Title>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setEditingRestriction(null);
            form.resetFields();
            setModalVisible(true);
          }}
        >
          Add Restriction
        </Button>
      </div>

      <Card>
        <Alert
          message="Domain Access Control"
          description={
            <Space direction="vertical">
              <Text>Configure which domains your workspace members can access and comment on.</Text>
              <ul>
                <li>Rules are evaluated from highest to lowest priority</li>
                <li>Use wildcards (*.example.com) to match all subdomains</li>
                <li>More specific rules should have higher priority</li>
              </ul>
            </Space>
          }
          type="info"
          showIcon
          style={{ marginBottom: '24px' }}
        />

        <Table
          loading={loading}
          columns={columns}
          dataSource={restrictions}
          rowKey="id"
        />
      </Card>

      <Modal
        title={editingRestriction ? 'Edit Domain Restriction' : 'New Domain Restriction'}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingRestriction(null);
          form.resetFields();
        }}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddEdit}
        >
          <Form.Item
            name="pattern"
            label="Domain Pattern"
            rules={[
              { required: true, message: 'Please enter domain pattern' },
              {
                pattern: /^(\*\.)?[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                message: 'Invalid domain pattern format'
              }
            ]}
            tooltip="Use format: example.com or *.example.com"
          >
            <Input placeholder="Enter domain pattern" />
          </Form.Item>

          <Form.Item
            name="isAllowed"
            label="Access"
            valuePropName="checked"
          >
            <Switch
              checkedChildren="Allowed"
              unCheckedChildren="Blocked"
            />
          </Form.Item>

          <Form.Item
            name="priority"
            label="Priority"
            initialValue={0}
            tooltip="Higher priority rules are evaluated first"
          >
            <InputNumber min={0} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DomainRestrictions;