import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { Link } from 'react-router-dom';
import { login } from "../utils/api";
import { useWorkspace } from '../components/WorkspaceContext';

export const LoginForm = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const { refreshWorkspaces } = useWorkspace();

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await login(values.email, values.password);
      await refreshWorkspaces(); // Ensure workspaces are loaded before proceeding
      message.success("Logged in successfully");
      onSuccess();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        message.error(error.response.data.error);
      } else {
        message.error("Login failed. Please check your credentials.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onFinish={handleSubmit} layout="vertical">
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: "Please enter your email" },
          { type: "email", message: "Please enter a valid email" }
        ]}
      >
        <Input placeholder="Enter your email" />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: true, message: "Please enter your password" }]}
      >
        <Input.Password placeholder="Enter your password" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} block>
          Log in
        </Button>
      </Form.Item>

      <div style={{ textAlign: 'right' }}>
        <Link to="/request-reset">Forgot password?</Link>
      </div>
    </Form>
  );
};

export default LoginForm;